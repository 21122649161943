import { render, staticRenderFns } from "./VTaxEntitySelector.vue?vue&type=template&id=0442dbe8"
import script from "./VTaxEntitySelector.vue?vue&type=script&lang=ts"
export * from "./VTaxEntitySelector.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports