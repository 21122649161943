/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddressRecordItem } from './AddressRecordItem';
import type { userPermissions } from './userPermissions';
import type { UserTaxInformationRecordItem } from './UserTaxInformationRecordItem';

/**
 * User Partial
 */
export type UserPartialItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Flag for record being active
     */
    active?: boolean;
    /**
     * Flag for user being required to change their password before continuing
     */
    requirePassReset?: boolean;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    lastVisit?: number;
    /**
     * User Region ID - used for sensative data field storage
     */
    regionId?: number;
    /**
     * User Prefix
     */
    userPrefix?: UserPartialItem.userPrefix;
    /**
     * User first name
     */
    firstName?: string;
    /**
     * User middle name
     */
    middleName?: string;
    /**
     * User last name
     */
    lastName?: string;
    /**
     * User display name
     */
    displayName?: string;
    /**
     * Always present display name
     */
    resolvedDisplayName?: string;
    /**
     * Username
     */
    userName?: string;
    /**
     * User Profile Img Thumbnail
     */
    avatar?: string;
    /**
     * User NPI - 10 digit numeric
     */
    npi?: string;
    /**
     * User Timezone
     */
    timeZone?: string;
    /**
     * Email Address
     */
    email?: string;
    /**
     * User Type ID
     */
    userType?: number;
    /**
     * User Type Display
     */
    typeDisplay?: string;
    /**
     * User Type Display Abvreviated
     */
    typeDisplayAbbr?: string;
    /**
     * User degrees
     */
    degrees?: string;
    /**
     * User's preferred language ID
     */
    languageId?: string;
    /**
     * User's free level
     */
    freeLevel?: UserPartialItem.freeLevel;
    /**
     * User's Instatution Type
     */
    instaType?: UserPartialItem.instaType;
    /**
     * User's Country
     */
    country?: string;
    /**
     * User's hourly rate
     */
    hourlyRate?: number;
    /**
     * Flag for noetic Expert
     */
    noeticExpert?: boolean;
    /**
     * CSR Review - Defined, not returned
     */
    csrReview?: UserPartialItem.csrReview;
    /**
     * voice Alteration - Defined, not returned
     */
    voiceAlteration?: UserPartialItem.voiceAlteration;
    /**
     * Flag for loginas permission
     */
    canLoginAs?: boolean;
    /**
     * Flag for loginas support permission
     */
    canLoginAsSupport?: boolean;
    /**
     * Flag for loginas test permission
     */
    canLoginAsTest?: boolean;
    /**
     * Flag for CSR View permission
     */
    allowedCSRView?: boolean;
    /**
     * Flag for CSR Admin permission
     */
    allowedCSRAdmin?: boolean;
    /**
     * User Permissions Granted.  Only configurable by Admins
     */
    permissions?: Array<userPermissions>;
    /**
     * User Permissions Denied.  Only configurable by Admins
     */
    permissionsDenied?: Array<userPermissions>;
    /**
     * Is this a test user?
     */
    isTest?: boolean;
    /**
     * Is this a support user?
     */
    isSupport?: boolean;
    /**
     * User Registration Status
     */
    regStatus?: UserPartialItem.regStatus;
    /**
     * User Registration Status - Display
     */
    regStatusDisplay?: string;
    /**
     * User Tax Form Status
     */
    taxFormStatus?: UserPartialItem.taxFormStatus;
    address?: AddressRecordItem;
    userTaxInformation?: UserTaxInformationRecordItem;
}

export namespace UserPartialItem {

    /**
     * User Prefix
     */
    export enum userPrefix {
        DR_ = 'Dr.',
        MR_ = 'Mr.',
        MRS_ = 'Mrs.',
        MS_ = 'Ms.',
        MX_ = 'Mx.',
    }

    /**
     * User's free level
     */
    export enum freeLevel {
        NONE = 'none',
        ASYNC = 'async',
        TELE = 'tele',
    }

    /**
     * User's Instatution Type
     */
    export enum instaType {
        ACADEMIC = 'academic',
        COMMUNITY = 'community',
    }

    /**
     * CSR Review - Defined, not returned
     */
    export enum csrReview {
        YES = 'yes',
        NO = 'no',
        AUTO = 'auto',
    }

    /**
     * voice Alteration - Defined, not returned
     */
    export enum voiceAlteration {
        UP = 'up',
        DOWN = 'down',
        NONE = 'none',
    }

    /**
     * User Registration Status
     */
    export enum regStatus {
        PENDING = 'pending',
        NEW = 'new',
        VERIFY_SENT = 'verify_sent',
        VERIFIED = 'verified',
        WELCOME_SENT = 'welcome_sent',
        SKIPPED = 'skipped',
        NEW_CREG = 'new_creg',
        INVITED = 'invited',
        PRE_PLAN_INVITE = 'pre_plan_invite',
    }

    /**
     * User Tax Form Status
     */
    export enum taxFormStatus {
        NOT_STARTED = 'not_started',
        IN_PROGRESS = 'in_progress',
        COMPLETED = 'completed',
    }


}
