<template>
  <v-container fluid>
    <div class="login-page-top-bar">
      <div class="login-page-width d-flex" :style="{ 'max-width': maxWidthValue }">
        <router-link :to="{ name: 'Login' }"><app-bar-logo /></router-link>
        <v-spacer />
        <language-selection-icon />
      </div>
    </div>
    <div class="login-page-width" :style="{ 'max-width': maxWidthValue }">
      <slot />
      <!-- <div :class="{ 'login-footer-wrap': true, mobile }"> -->
      <div v-if="!hideFooter" class="login-footer pb-8">
        <v-row>
          <v-col class="text-center py-0 py-md-2" cols="12" sm="4">
            <a :href="infoDomain" target="_blank" id="appCopyright" rel="noopener">{{ name }}</a>
          </v-col>
          <v-col class="text-center py-0 py-md-2" cols="12" sm="4">
            <a :href="`${infoDomain}/terms-of-use`" target="_blank" id="appTermsOfUse">Terms of Use</a>
          </v-col>
          <v-col class="text-center py-0 py-md-2" cols="12" sm="4">
            <a :href="`${infoDomain}/privacy`" target="_blank" id="appPrivacy">Privacy Policy</a>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.login-page-width {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  width: 100%;
  // max-width: 800px;
  margin: 0 auto;
}
.login-page-top-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: flex-start;
  height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.login-footer-wrap {
  // Space to accomodate the absolute div so it doesnt overlap
  height: 70px;

  .login-footer {
    position: absolute;
    bottom: 14px;
    left: 0;
    right: 0;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
  }

  &.mobile {
    height: inherit;

    // .row {
    //   width: 100%;

    //   .login-footer {
    //     position: relative;
    //     left: inherit;
    //     right: inherit;
    //     margin: 0 auto;
    //   }
    // }
  }
}
</style>
<script lang="ts">
import Vue from "vue";
import LoginCard from "@/components/auth/LoginCard.vue";

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";
import AppBarLogo from "@/components/theme/AppBarLogo.vue";
import LanguageSelectionIcon from "@/components/layout/LanguageSelectIcon.vue";

import { ThemeStoreInterface } from "@/store/theme/types";
const { mapGetters } = createHelpers<ThemeStoreInterface>();
const getters = mapGetters(["name", "domain", "infoDomain"]);

export default Vue.extend({
  components: {
    AppBarLogo,
    LanguageSelectionIcon
  },
  props: {
    maxWidth: {
      type: String,
      default: "800px"
    },
    hideFooter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...getters,
    maxWidthValue(): string | undefined {
      if (this.maxWidth.trim() === "" || this.maxWidth === "auto") {
        return undefined;
      }
      return this.maxWidth;
    },
    thisYear(): string {
      return new Date().getFullYear().toString();
    },
    mobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
});
</script>