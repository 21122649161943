/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressRecordItem } from '../models/AddressRecordItem';
import type { BioRecordItem } from '../models/BioRecordItem';
import type { CommLogItem } from '../models/CommLogItem';
import type { meta } from '../models/meta';
import type { NotificationPreferencesRecordItem } from '../models/NotificationPreferencesRecordItem';
import type { PhoneRecordItem } from '../models/PhoneRecordItem';
import type { TimeOffRecordItem } from '../models/TimeOffRecordItem';
import type { UserBankRecordItem } from '../models/UserBankRecordItem';
import type { userChangePassword } from '../models/userChangePassword';
import type { UserDomainRecordItem } from '../models/UserDomainRecordItem';
import type { UserInviteRecordItem } from '../models/UserInviteRecordItem';
import type { UserItem } from '../models/UserItem';
import type { UserPartialItem } from '../models/UserPartialItem';
import type { userPhoto } from '../models/userPhoto';
import type { UserPhotoItem } from '../models/UserPhotoItem';
import type { UserRecordItem } from '../models/UserRecordItem';
import type { UserRegistrationInput } from '../models/UserRegistrationInput';
import type { UserSpecialtyRecordItem } from '../models/UserSpecialtyRecordItem';
import type { UserTaxInformationRecordItem } from '../models/UserTaxInformationRecordItem';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Find User bank by User ID. Sort by date, amount
     * Return User bank Records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getUserBankByUserId({
        id,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Record ID **/
        id: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<UserBankRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/userbank`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user userBank
     * Creates a record based on data given
     * @result UserBankRecordItem successful operation
     * @throws ApiError
     */
    public static async createUserBank({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** UserBank object to be created **/
        requestBody: UserBankRecordItem,
    }): Promise<UserBankRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/userbank`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find Comm Log by User ID
     * Return User Comm Log Records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getUserCommLogByUserId({
        id,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Record ID **/
        id: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<CommLogItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/commlog`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get initial setup data for a registration form
     * Get initial setup data for a registration form
     * @result any successful operation
     * @throws ApiError
     */
    public static async getRegistrationSetup({
        key,
        slug,
    }: {
        /** Invitation UUID **/
        key?: string,
        /** Plan Slug **/
        slug?: string,
    }): Promise<{
        /**
         * CMS UID for registration-header type
         */
        cmsDocId?: string,
        /**
         * Header HTML if cmsDocID is empty
         */
        header?: string,
        /**
         * Theme identifier
         */
        theme?: string,
        userType?: number,
        invite?: UserInviteRecordItem,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/register/setup`,
            query: {
                'key': key,
                'slug': slug,
            },
            errors: {
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find User Addres by User ID
     * Find User Addres by User ID
     * @result AddressRecordItem successful operation
     * @throws ApiError
     */
    public static async getUserAddressByUId({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<AddressRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/address`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing user address
     * Update a record based on data given
     * @result AddressRecordItem successful operation
     * @throws ApiError
     */
    public static async updateAddress({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Address object that needs to be updated **/
        requestBody: AddressRecordItem,
    }): Promise<AddressRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/${id}/address`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user address
     * Creates a record based on data given
     * @result AddressRecordItem successful operation
     * @throws ApiError
     */
    public static async createAddress({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Address object to be created **/
        requestBody: AddressRecordItem,
    }): Promise<AddressRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/address`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find User Bio by User ID
     * Return a single User Bio
     * @result BioRecordItem successful operation
     * @throws ApiError
     */
    public static async getUserBioById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<BioRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/bio`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing user Bio
     * Update a record based on data given
     * @result BioRecordItem successful operation
     * @throws ApiError
     */
    public static async updateBio({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Bio object that needs to be updated **/
        requestBody: BioRecordItem,
    }): Promise<BioRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/${id}/bio`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user bio
     * Creates a record based on data given
     * @result BioRecordItem successful operation
     * @throws ApiError
     */
    public static async createBio({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Bio object to be created **/
        requestBody: BioRecordItem,
    }): Promise<BioRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/bio`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find User by ID
     * Return a single User
     * @result UserItem successful operation
     * @throws ApiError
     */
    public static async getUserById({
        id,
        inclBio = false,
    }: {
        /** Record ID **/
        id: number,
        /** Include Bio Record with Response. **/
        inclBio?: boolean,
    }): Promise<UserItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/profile/${id}`,
            query: {
                'inclBio': inclBio,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List Users - filterable by name, email.  Get by associated records. Sort by id, email, lastLogin, created, type
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getUserList({
        userType,
        search,
        diagnosisId,
        diagnosisGroupName,
        activeInclude = 'all',
        expertInclude = 'all',
        testInclude = 'all',
        supportInclude = 'all',
        diagnosisGroupId,
        diagnosisDomainId,
        poolId,
        planId,
        excludePoolId,
        excludeDiagnosisGroupId,
        excludeDiagnosisDomainId,
        excludePlanId,
        inclAddress = false,
        regStatus,
        taxStatus,
        sort,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records associated with a given User Type **/
        userType?: number,
        /** Search user first, middle, last, and email for string.  ID to find specific user. **/
        search?: string,
        /** Find records associated with a given Diagnosis  **/
        diagnosisId?: number,
        /** Find Users associated with a given Diagnosis Group by Name **/
        diagnosisGroupName?: string,
        /** Records to Include based on Active Flag.  Allows for inactive only if desired. **/
        activeInclude?: 'all' | 'active' | 'inactive',
        /** Records to Include based on Expert Flag.  Allows for expert or non-expert only if desired. **/
        expertInclude?: 'all' | 'expert' | 'non_expert',
        /** Records to Include based on Test Flag.  Allows for Test only if desired. **/
        testInclude?: 'all' | 'test' | 'live',
        /** Records to Include based on Support Flag.  Allows for Support only if desired. **/
        supportInclude?: 'all' | 'support' | 'live',
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Find records associated with a given Pool **/
        poolId?: number,
        /** Find records associated with a given Plan **/
        planId?: number,
        /** Exclude records associated with a given Pool **/
        excludePoolId?: number,
        /** Exclude records associated with a given Diagnosis Group **/
        excludeDiagnosisGroupId?: number,
        /** Exclude records associated with a given Diagnosis Domain **/
        excludeDiagnosisDomainId?: number,
        /** Exclude records associated with a given Plan **/
        excludePlanId?: number,
        /** Include Address Record with Response. **/
        inclAddress?: boolean,
        /** User Registration Status **/
        regStatus?: 'pending' | 'new' | 'verify_sent' | 'verified' | 'welcome_sent' | 'skipped' | 'new_creg' | 'invited' | 'pre_plan_invite',
        /** User Tax Form Status **/
        taxStatus?: 'not_started' | 'in_progress' | 'completed' | 'all',
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<UserPartialItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/list`,
            query: {
                'userType': userType,
                'search': search,
                'diagnosisId': diagnosisId,
                'diagnosisGroupName': diagnosisGroupName,
                'activeInclude': activeInclude,
                'expertInclude': expertInclude,
                'testInclude': testInclude,
                'supportInclude': supportInclude,
                'diagnosisGroupId': diagnosisGroupId,
                'diagnosisDomainId': diagnosisDomainId,
                'poolId': poolId,
                'planId': planId,
                'excludePoolId': excludePoolId,
                'excludeDiagnosisGroupId': excludeDiagnosisGroupId,
                'excludeDiagnosisDomainId': excludeDiagnosisDomainId,
                'excludePlanId': excludePlanId,
                'inclAddress': inclAddress,
                'regStatus': regStatus,
                'taxStatus': taxStatus,
                'sort': sort,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find User Notification Preferences by User ID
     * Return a single User Notification Preference
     * @result NotificationPreferencesRecordItem successful operation
     * @throws ApiError
     */
    public static async getUserNotificationPreferenceById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<NotificationPreferencesRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/notification`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing user Notification Preferences
     * Update a record based on data given
     * @result NotificationPreferencesRecordItem successful operation
     * @throws ApiError
     */
    public static async updateNotificationPreferences({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Notification Preferences object that needs to be updated **/
        requestBody: NotificationPreferencesRecordItem,
    }): Promise<NotificationPreferencesRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/${id}/notification`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user Notification Preferences
     * Creates a record based on data given
     * @result NotificationPreferencesRecordItem successful operation
     * @throws ApiError
     */
    public static async createNotificationPreferences({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Notification Preferences object to be created **/
        requestBody: NotificationPreferencesRecordItem,
    }): Promise<NotificationPreferencesRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/notification`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find User Partial by ID
     * Return a single User Partial
     * @result UserPartialItem successful operation
     * @throws ApiError
     */
    public static async getUserPartialById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<UserPartialItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing user
     * Update a record based on data given
     * @result UserRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateUser({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** user object that needs to be updated **/
        requestBody: UserRecordItem,
    }): Promise<UserRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find User Phone by User ID
     * Return a single User Phone Record
     * @result PhoneRecordItem successful operation
     * @throws ApiError
     */
    public static async getUserPhoneById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<PhoneRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/phone`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing user Phone
     * Update a phone record based on data given
     * @result PhoneRecordItem successful operation
     * @throws ApiError
     */
    public static async updatePhone({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Phone object that needs to be updated **/
        requestBody: PhoneRecordItem,
    }): Promise<PhoneRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/${id}/phone`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user phone
     * Creates a phone record based on data given
     * @result PhoneRecordItem successful operation
     * @throws ApiError
     */
    public static async createPhone({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Phone object to be created **/
        requestBody: PhoneRecordItem,
    }): Promise<PhoneRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/phone`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find User Photo by User ID
     * Return a single User Photo
     * @result UserPhotoItem successful operation
     * @throws ApiError
     */
    public static async getUserPhotoById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<UserPhotoItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/photo`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Uploads an image replacing any existing image
     * User profile image upload
     * @result userPhoto successful operation
     * @throws ApiError
     */
    public static async photoUpload({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Profile photo upload **/
        requestBody: any,
    }): Promise<userPhoto> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/photo`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * remove an image
     * Remove User profile image
     * @result any successful operation
     * @throws ApiError
     */
    public static async photoRemove({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/user/${id}/photo`,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find User Speciatly by User ID.  Authentication Involved.
     * Return a single Users Specialty Records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getUserSpecialtyByUserId({
        id,
        csrView = true,
        dxDomainOnly = false,
    }: {
        /** Record ID **/
        id: number,
        /** CSR vs User View.  Requests by non-CSR will set false automatically **/
        csrView?: boolean,
        /** Group By Domain **/
        dxDomainOnly?: boolean,
    }): Promise<{
        data?: Array<UserDomainRecordItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/specialty`,
            query: {
                'csrView': csrView,
                'dxDomainOnly': dxDomainOnly,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find User Tax Information by User ID
     * Find User Tax Information by User ID
     * @result UserTaxInformationRecordItem successful operation
     * @throws ApiError
     */
    public static async getUserTaxInformationByUId({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<UserTaxInformationRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/tax/information`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing user tax information
     * Update a record based on data given
     * @result UserTaxInformationRecordItem successful operation
     * @throws ApiError
     */
    public static async updateTaxInformation({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Tax Information object that needs to be updated **/
        requestBody: UserTaxInformationRecordItem,
    }): Promise<UserTaxInformationRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/${id}/tax/information`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user tax information
     * Creates a record based on data given
     * @result UserTaxInformationRecordItem successful operation
     * @throws ApiError
     */
    public static async createTaxInformation({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** User Tax Information object to be created **/
        requestBody: UserTaxInformationRecordItem,
    }): Promise<UserTaxInformationRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/tax/information`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find User Time Off by User ID
     * Return a single User Time Off Records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getUserTimeOffById({
        id,
        activeOnly = true,
    }: {
        /** Record ID **/
        id: number,
        /** Records to Include based on Active Flag. **/
        activeOnly?: boolean,
    }): Promise<{
        data?: Array<TimeOffRecordItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/timeoff`,
            query: {
                'activeOnly': activeOnly,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user time off
     * Creates a record based on data given
     * @result TimeOffRecordItem successful operation
     * @throws ApiError
     */
    public static async createTimeOff({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** TimeOff object to be created **/
        requestBody: TimeOffRecordItem,
    }): Promise<TimeOffRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/timeoff`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Register a new user
     * Creates a record based on data given
     * @result UserRecordItem Successful operation
     * @throws ApiError
     */
    public static async registerUser({
        requestBody,
    }: {
        /** User registration form data **/
        requestBody: UserRegistrationInput,
    }): Promise<UserRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/register/user`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user
     * Creates a record based on data given
     * @result UserRecordItem Successful operation
     * @throws ApiError
     */
    public static async createUser({
        requestBody,
    }: {
        /** user  object to be created **/
        requestBody: UserRecordItem,
    }): Promise<UserRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a diagnosis group to a user
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addUserDiagnosisGroup({
        id,
        groupId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of diagnosis group to add to user **/
        groupId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/diagnosisGroup/${groupId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a diagnosis group from a user
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteUserDiagnosisGroup({
        id,
        groupId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of diagnosis group to remove from user **/
        groupId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/user/${id}/diagnosisGroup/${groupId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing users password
     * Update a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async updateUserPassword({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** user object that needs to be updated **/
        requestBody: userChangePassword,
    }): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/${id}/password`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Generate and send an SMS confirmation for a user
     * Return Phone Item or error
     * @result PhoneRecordItem successful operation
     * @throws ApiError
     */
    public static async getUserPhoneSmsConf({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<PhoneRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/${id}/phone/smsconf`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Accept and verify SMS confirmation for a user
     * Return Phone Item or error
     * @result PhoneRecordItem successful operation
     * @throws ApiError
     */
    public static async postUserPhoneSmsConf({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /**  **/
        requestBody?: {
            /**
             * SMS Confirmation Pin
             */
            pin?: number,
        },
    }): Promise<PhoneRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/phone/smsconf`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing user Specialty
     * Update a record based on data given
     * @result UserSpecialtyRecordItem successful operation
     * @throws ApiError
     */
    public static async updateSpecialty({
        id,
        diagnosisId,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Diagnosis ID **/
        diagnosisId: number,
        /** Specialty object that needs to be updated **/
        requestBody: UserSpecialtyRecordItem,
    }): Promise<UserSpecialtyRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/${id}/specialty/${diagnosisId}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user specialty
     * Creates a record based on data given
     * @result UserSpecialtyRecordItem successful operation
     * @throws ApiError
     */
    public static async createSpecialty({
        id,
        diagnosisId,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Diagnosis ID **/
        diagnosisId: number,
        /** Specialty object to be created **/
        requestBody: UserSpecialtyRecordItem,
    }): Promise<UserSpecialtyRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/${id}/specialty/${diagnosisId}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing user Time off
     * Update a record based on data given
     * @result TimeOffRecordItem successful operation
     * @throws ApiError
     */
    public static async updateTimeOff({
        id,
        timeOffId,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** ID of Time Off to update **/
        timeOffId: number,
        /** TimeOff object that needs to be updated **/
        requestBody: TimeOffRecordItem,
    }): Promise<TimeOffRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/${id}/timeoff/${timeOffId}`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Delete an existing user Time off
     * Delete a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteTimeOff({
        id,
        timeOffId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of Time Off to update **/
        timeOffId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/user/${id}/timeoff/${timeOffId}`,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}