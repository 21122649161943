import Vue from "vue";
import Vuex from "vuex-typescript-interface";
import jwt_decode from "jwt-decode";
import { Route } from "vue-router";
import { RootStoreInterface } from "./types";
import {
  OpenAPI,
  UserPartialItem,
  CountryRecordItem,
  LanguageRecordItem,
  AuthService,
  StaticService,
  ApiError,
  OAuthAppsItem,
  AttachmentRecordItem,
  UserfilesService,
  UserService,
  StateRecordItem
} from "@/service";
import { loadLanguageAsync, i18n } from "@/plugins/i18n";
import { PlanTypeName, Languages, UserType, Domains } from "@/config";
import { openreplayTracker, openreplayVuexPlugin } from "@/plugins/openreplay";

import index from "./discussionIndex";
import query from "./query";
import theme from "./theme";
import terms from "./terms";
import dashboard from "./dashboard";
import router from "../router";
import reports from "./reports";
import { AuthenticationResult, PublicClientApplication } from "@azure/msal-browser";

Vue.use(Vuex);

// TODO Add currently selected portal language to the store backed by localStorage,
// and potentially reporting to the server as well.

const plugins = openreplayVuexPlugin ? [openreplayVuexPlugin as any] : [];

export default new Vuex.Store<RootStoreInterface>({
  plugins,
  state: {
    currentVersion: "1.0.0", // This could be global as well
    token: null,
    canPost: false,
    exp: 0,
    userPartial: null,
    authUserPartial: null,
    cachedUId: null,
    serverVersionString: "",
    progressCheckCallbackData: null,
    redirectRouteData: null,
    userFilesData: [],
    authError: null,
    snackbarText: "",
    snackbarCol: undefined,
    canCloseSnackbar: false,
    lang: "en",
    showLanguageSelector_: false,
    showAdminMenu: false,
    taxNotificationOpen: false,
    showQueryList: true,
    draftModalOpen: false,
    isEndUserView: false,
    typeformWindowOpen: false,

    msalAccessToken: "",

    // TODO Global static data - Any of these items that don't change can have their definition moved out of the store, and have the
    // getter return the global object. this should improve performance as these need not be instrumented for reactivity
    // TODO Should these be loaded from the server at login time?
    userTypeNamesData: {
      1: "Client",
      2: "Expert",
      3: "CSR",
      5: "Physician",
      11: "Plan Manager",
      12: "Blinded"
    },

    invitationStatus: {
      all: "All",
      new_or_sent:"New/Sent",
      new: "New",
      sent: "Sent",
      accepeted: "Accepted",
      declined: "Declined",
      cancelled: "Cancelled",
      expired: "Expired"
    },

    expertSelectionModesData: {
      all: "Funding Source and Plan",
      grant_first: "Funding Source First",
      plan_first: "Plan First",
      grant_only: "Funding Source Only",
      plan_only: "Plan Only",
      plan_select: "Pool Select",
      user_choice: "User Choice"
    },

    // TODO: Add proper country data
    countriesData: null,
    countryStatesData: [],

    languagesData: [],

    crsReviewsData: {
      yes: "Yes",
      no: "No",
      auto: "Auto"
    },

    userPrefixData: {
      empty_string: " ",
      dr: "Dr.",
      mr: "Mr.",
      mrs: "Mrs.",
      ms: "Ms.",
      mx: "Mx."

    },

    // Onviv Experts
    expertIncludeData:{
      expert: "Enabled Only",
      non_expert: "Disabled Only",
      all: "All"
    },

    // Account Status
    accountStatusData:{
      active: "Enabled",
      inactive: "Disabled",
      all: "All"
    },

    // User Category
    testIncludeData: {
      live: "Exclude Test Users",
      test: "Test Users Only",
      all: "All",
    },

    voicePitchesData: {
      up: "Up",
      down: "Down",
      none: "None"
    },

    timeZonesData: {},

    userTypeAbbrData: {
      0: "PEN",
      1: "CLI",
      2: "EXP",
      3: "CSR",
      4: "GST",
      5: "NWT",
      11: "PLM",
      12: "BLD"
    },

   queryStatusesData: {
      open: "Open",
      closed: "Closed",
      answered: "Answered",
      held: "Held",
      pending_charge: "Pending Charge",
      charged: "Charged",
      create: "Create",
      csrhold: "CSRHold",
      cancelled: "Cancelled",
      pending_survey: "Survey Pending"
    },

    inviteActionsData: ["Accept", "Decline", "Missedout", "Cancelled", "Closed", "Invited"],


    groupByOptionsData: [
      "diagnosis",
      "diagnosisGroup",
      "diagnosisDomain",
      "userType",
      "country",
      "planType",
      "plan",
      "grant"
    ],

    tagScopesData: {
      user: "User",
      team: "Team",
      global: "Global"
    }
  },

  getters: {
    version: state => state.currentVersion,
    serverVersion: state => state.serverVersionString,

    // URL based environment checks
    currentEnvironment(state): string {
      const hostname = window.location.hostname;

      if (hostname.startsWith("app.") || hostname.startsWith("my.")) {
        return "prod";
      }
      if (hostname.startsWith("staging.")) {
        return "staging";
      }
      if (hostname.startsWith("dev.") || hostname.includes("feature-")) {
        return "dev"; // Feature branches are treated as dev
      }
      if (hostname === "localhost" || hostname.startsWith("127.") || hostname === "0.0.0.0") {
        return "local";
      }

      // be restrictive and assume prod
      return "prod"; // Catch-all case
    },


    isLoggedIn(state): boolean {
      return !!state.token;
    },

    apiToken(state): string | null {
      return state.token;
    },
    tokenExpiration(state): number {
      return state.exp;
    },

    user(state): UserPartialItem | null {
      return state.userPartial;
    },
    authUser(state): UserPartialItem | null {
      return state.authUserPartial;
    },
    userCanPost(state): boolean {
      return state.canPost;
    },

    userFiles(state): Array<AttachmentRecordItem> {
      return state.userFilesData;
    },

    activeUserType(state): number {
      return state.userPartial?.userType ?? 0;
    },

    isAllowedCSRAdmin(state): boolean {
      return state.userPartial?.allowedCSRAdmin ?? false;
    },

    isAllowedCSRView(state): boolean {
      return state.userPartial?.allowedCSRView ?? false;
    },

    errorText(state): string | null {
      if (state.authError instanceof ApiError) {
        return state.authError.message;
      }
      return state.authError ? state.authError : null;
    },

    snackbar: state => state.snackbarText,
    snackbarColor: state => state.snackbarCol,
    snackbarCanClose: state => state.canCloseSnackbar ?? false,

    currentLanguage: state => state.lang,
    currentLocale: state => Languages[state.lang]?.locale ?? "en-us",
    showLanguageSelector: state => state.showLanguageSelector_,
    isAdminMenuOpen: state => state.showAdminMenu,
    isTaxNotificationOpen: state =>  state.taxNotificationOpen,
    cachedUserId: state => state.cachedUId,
    isQueryListOpen: state => state.showQueryList,
    isDraftModalOpen: state  => state.draftModalOpen,


    // Static items can probably be imported from @/config instead of the store
    userTypes: state => state.userTypeNamesData,
    invitations: state => state.invitationStatus,
    planTypes: _state => PlanTypeName, // state.planTypeNamesData,

    countries: state => state.countriesData,
    countryStates: state => state.countryStatesData,
    languages: state => state.languagesData,
    csrReviews: state => state.crsReviewsData,
    userPrefixes: state => state.userPrefixData,
    voicePitches: state => state.voicePitchesData,
    timeZones: state => state.timeZonesData,
    expertSelectionModes: state => state.expertSelectionModesData,
    userTypeAbbreviations: state => state.userTypeAbbrData,
    queryStatuses: state => state.queryStatusesData,
    inviteActions: state => state.inviteActionsData,
    groupByOptions: state => state.groupByOptionsData,
    tagScopes: state => state.tagScopesData,
    progressCheckCallback: state => state.progressCheckCallbackData,
    redirectRoute: state => state.redirectRouteData,
    accountStatuses: state => state.accountStatusData,
    expertIncluded: state => state.expertIncludeData,
    testsIncludeData: state => state.testIncludeData,
    endUserView: state => state.isEndUserView,
    isTypeFormWindowOpen: state =>  state.typeformWindowOpen
  },
  mutations: {
    initStore(state): void {
      state.token = null;
      state.userPartial = null;
      state.authError = null;
      state.canPost = false;
      state.snackbarText = "";
      state.lang = "en";
      state.showAdminMenu = false;
      state.taxNotificationOpen = false;
      state.showQueryList = true;
      state.draftModalOpen  = false;
      OpenAPI.TOKEN = "";

      const savedLang = localStorage.getItem("portalLang");
      if (savedLang) {
        state.lang = savedLang;
        loadLanguageAsync(savedLang);
        //i18n.locale = savedLang;
      }
    },
    setLogin(
      state,
      payload: {
        user: UserPartialItem;
        token: string;
        exp: number;
        authUser?: UserPartialItem;
        theme?: string;
        canPost: boolean;
      }
    ): void {
      state.userPartial = payload.user;
      state.token = payload.token;
      state.exp = payload.exp;
      state.authUserPartial = payload.authUser ?? null;
      state.authError = null;
      state.cachedUId = null;
      state.canPost = payload.canPost;
      // Populated bearer token for all future requests
      OpenAPI.TOKEN = payload.token;

      // Track ACTUAL user
      let email = `${payload.user.email}`,
        userIdNum = `${payload.user.id}`,
        loginAsEmail = "";
      if (payload.authUser) {
        loginAsEmail = email;
        email = `${payload.authUser.email}`;
        userIdNum = `${payload.authUser.id}`;
        // } (${payload.authUser.id})`;
      }
      if (openreplayTracker) {
        openreplayTracker.setUserID(email);
        openreplayTracker.setMetadata("loginAsId", loginAsEmail);
        openreplayTracker.setMetadata("userId", userIdNum);
      }
    },

    clearLogin(state): void {
      state.userPartial = null;
      state.token = null;
      state.exp = 0;
      state.authUserPartial = null;
      state.authError = null;
      state.userFilesData = [];
      // Populated bearer token for all future requests
      OpenAPI.TOKEN = "";

      if (openreplayTracker) {
        openreplayTracker.setUserID("");
        openreplayTracker.setMetadata("loginAsId", "");
        openreplayTracker.setMetadata("userId", "");
      }
    },

    setToken(state, token: string | null): void {
      state.token = token;
    },
    setTokenExpiration(state, exp: number): void {
      state.exp = exp;
    },

    setProgressCheckCallback(state, payload: (() => Promise<boolean>) | null): void {
      state.progressCheckCallbackData = payload ? { callback: payload } : null;
    },

    setRedirectRoute(state, route: Record<string, Route> | null): void {
      state.redirectRouteData = route;
    },
    setCacheUserId(state, uid: string | null): void {
      state.cachedUId = uid;
    },
    setUserFiles(state, files: Array<AttachmentRecordItem>): void {
      state.userFilesData = files;
    },

    clearUserFiles(state): void {
      state.userFilesData = [];
    },
    setServerVersion(state, v: string): void {
      state.serverVersionString = v;
    },

    setError(state, error): void {
      state.authError = error;
    },

    setSnackbar(state, text: string | { text: string; color: string; canClose?: boolean }): void {
      if (typeof text === "string") {
        state.snackbarText = text;
        state.snackbarCol = undefined;
        state.canCloseSnackbar = false;
      } else {
        state.snackbarText = text.text;
        state.snackbarCol = text.color;
        state.canCloseSnackbar = text.canClose;
      }
    },

    setLanguage(state, lang: string): void {
      state.lang = lang;
      localStorage.setItem("portalLang", lang);
      loadLanguageAsync(lang);
      // i18n.locale = lang;
    },
    setAdminMenu(state, open: boolean): void {
      state.showAdminMenu = open;
    },
    updateUserPartialTax(state,user: UserPartialItem): void {
      if (state.userPartial) state.userPartial.taxFormStatus = user.taxFormStatus;
    },
    setTaxNotificationValue(state, open: boolean): void {
      state.taxNotificationOpen = open;
    },
    setTypeFormWindow(state, val:boolean ): void {
      state.typeformWindowOpen = val
    },
    setQueryList(state, open: boolean): void {
      state.showQueryList = open;
    },

    setDraftModalOpen(state, open: boolean): void {
      state.draftModalOpen = open;
    },

    setCountries(state, countries: CountryRecordItem[]): void {
      state.countriesData = countries;
    },
    setCountryStates(state, states: Array<StateRecordItem>): void {
      state.countryStatesData = states;
    },
    setLanguages(state, items: Array<LanguageRecordItem>): void {
      state.languagesData = items;
    },
    setTimeZones(state, zones: Record<string, string>): void {
      state.timeZonesData = zones;
    },
    setMsalAccessToken(state, token: string): void {
      state.msalAccessToken = token;
    },
    setEndUserView(state, b:boolean): void {
      state.isEndUserView = b;
    },
  },
  actions: {
    async login(
      { commit, dispatch },
      payload: { username?: string; password?: string; token?: string; tokenKey?: string }
    ): Promise<string | null> {
      commit("setError", null);

      return AuthService.webLogin({
        requestBody: {
          // username: payload.username,
          // password: payload.password,
          // token: payload.token,
          deviceId: "web",
          deviceToken: "",
          ...payload
        } as any, // TODO Remove this - temporary work around until server is updated for MS365 auth
        authorization: "Basic Y2FuY2VyZXhwZXJ0d2ViNzY2NTQ0OmNhbmNlcmV4cGVydG5vdzc3NzZzZWNyZXQ="
      })
        .then(async (r) => {
          commit("setCacheUserId", null);
          commit("setLogin", {
            user: r.user as UserPartialItem,
            token: r.token as string,
            exp: r.exp ?? 0,
            authUser: undefined,
            canPost: Boolean(r.canPost)
          });
          localStorage.setItem("cen-login", JSON.stringify(r));
          dispatch("applyTheme", r.theme ?? (await dispatch("getUserTheme", r.user?.userType ?? 0)) ?? "default");
          dispatch("clearIndexCms");
          Vue.prototype.$eventBus.$emit("initialize");
          Vue.prototype.$eventBus.$emit("reloadIndex");
          return r.token as string;
        })
        .catch((r: ApiError) => {
          if (r.body?.errorKey === "auth.oauth.required") {
            if (router.currentRoute.path != "/csr") {
              Vue.prototype.$alert(r.body?.message ?? "Please Authenticate");
              router.replace("/csr");
            }
            return null;
          }
          if (r.body?.message) {
            r.message = r.body.message;
          }
          if (r.status === 400) {
            r.message = i18n.t(`messages.signInError`) as string;
          } else if (r.status === 500) {
            r.message = i18n.t(`messages.signIn500Error`) as string;
          }
          commit("setError", r);
          return null;
        });
    },
    async refreshWebToken({ getters }): Promise<boolean> {
      try {
        // TODO: Call api to refresh token
        const id = getters.user?.id ?? getters.authUser?.id;
        if (id) {
          await UserService.getUserById({ id: id }) ?? {};
          return true;
        }
      } catch (e) {
        // Ok to do nothing
        console.log("Unable to save refreshed token");
      }
      return false;
    },
    async resetCredentials({ commit, dispatch }): Promise<void> {
      localStorage.removeItem("cen-login");
      commit("clearLogin");
      dispatch("clearCms");
    },
    async updateTaxFormStatus({ commit }, newStatus: string): Promise<void> {
      try {
        const cenLoginData = JSON.parse(localStorage.getItem("cen-login") || "{}");

        if (cenLoginData?.user) {
          cenLoginData.user.taxFormStatus = newStatus;
    
          // Save updated data back to localStorage
          localStorage.setItem("cen-login", JSON.stringify(cenLoginData));
    
          // Update Vuex state by committing the mutation
          commit("updateUserPartialTax", cenLoginData.user );

        }
      } catch (error) {
        console.error("Error updating", error);
      }
    },

    async logout({ dispatch }): Promise<void> {
      AuthService.logout()
        .catch(e => {
          console.log(e);
          return null;
        })
        .then(() => dispatch("resetCredentials"))
        .then(() => dispatch("initialize"));
    },
    async loginAs({ commit, dispatch }, payload: { userId: number | string }): Promise<string | null> {
      return AuthService.loginAs({
        userId: String(payload.userId)
      })
        .then(async (r) => {
          commit("setLogin", {
            user: r.user as UserPartialItem,
            token: r.token as string,
            exp: r.exp ?? 0,
            authUser: r.authUser,
            canPost: Boolean(r.canPost)
          });
          localStorage.setItem("cen-login", JSON.stringify(r));
          dispatch("applyTheme", r.theme ?? (await dispatch("getUserTheme", r.user?.userType ?? 0)) ?? "default");
          dispatch("clearIndexCms");
          Vue.prototype.$eventBus.$emit("initialize");
          Vue.prototype.$eventBus.$emit("reloadIndex");

          if (router.currentRoute.path != "/") {
            router.replace({ path: "/", hash: "#" }).catch(() => {
              /* This will redirect again and that may be considred an error by vue */
            });
          }
          return r.token as string;
        })
        .catch((r: ApiError) => {
          if (r.status === 400) {
            r.message = i18n.t(`messages.signInError`) as string;
          } else if (r.status === 500) {
            r.message = i18n.t(`messages.signIn500Error`) as string;
          }
          commit("setError", r);
          return null;
        });
    },
    async cancelLoginAs({ commit, dispatch, getters }): Promise<string | null> {
      if (getters.progressCheckCallback && !(await getters.progressCheckCallback.callback())) return null;
      return AuthService.cancelLoginAs()
        .then(async (r) => {
          commit("setLogin", {
            user: r.user as UserPartialItem,
            token: r.token as string,
            exp: r.exp ?? 0,
            authUser: undefined,
            canPost: Boolean(r.canPost)
          });
          localStorage.setItem("cen-login", JSON.stringify(r));
          Vue.prototype.$eventBus.$emit("initialize");
          Vue.prototype.$eventBus.$emit("reloadIndex");
          dispatch("applyTheme", r.theme ?? (await dispatch("getUserTheme", r.user?.userType ?? 0)) ?? "default");

          let hash = undefined;
          if (router.currentRoute.hash != "#") {
            hash = "#";
          }
          router.replace({ path: "/", hash: hash }).catch(() => {
            /* Not an error */
          });

          return r.token as string;
        })
        .catch((r: ApiError) => {
          if (r.status === 400) {
            r.message = i18n.t(`messages.signInError`) as string;
          }
          commit("setError", r);
          return null;
        });
    },

    // If the token is refreshed, update local storage with it
    async updateToken({ commit }, payload: { token: string }): Promise<void> {
      const loginData = localStorage.getItem("cen-login");
      if (loginData) {
        try {
          // Save the token for the API instance
          OpenAPI.TOKEN = payload.token;
          // Decode token to get expiry time
          const token = jwt_decode(payload.token);
          // Save the token in local storage
          const r = JSON.parse(loginData);
          r.token = payload.token;
          // Update  expiry time
          if (token) {
            r.exp = (token as any).exp ?? 0;
          }
          localStorage.setItem("cen-login", JSON.stringify(r));
          // Update new token to store
          commit("setTokenExpiration", r.exp);
          commit("setToken", r.token);
        } catch (e) {
          // Ok to do nothing
          console.log("Failed to save refreshed token");
        }
      }
    },
    // Refresh token from locale storage
    async refreshToken({commit, state}): Promise<void>  {
      const loginData = localStorage.getItem("cen-login");
      if (loginData) {
        try {
          const r = JSON.parse(loginData);
          if (r.token != state.token) {
            // Load user data if required
            if (!state.userPartial || !state.userPartial.id || (r.user.id != state.userPartial.id)) {
              const requiredReload = !!(!state.authUserPartial && r.authUser) || !!(state.authUserPartial && !r.authUser);
              commit("setLogin", {
                user: r.user as UserPartialItem,
                token: r.token as string,
                exp: r.exp ?? 0,
                authUser: r.authUser ?? undefined,
                canPost: Boolean(r.canPost)
              });
              if (requiredReload) {
                // Redirection to same path with #
                let hash = undefined;
                if (router.currentRoute.hash != "#") { hash = "#"; }
                router.replace({ path: "/", hash: hash }).catch(() => {
                  /* This will redirect again and that may be considred an error by vue */
                });
              }
            } else {
              // Update token to authenticate api
              OpenAPI.TOKEN = r.token;
              // Decode token to get expiry time
              const token = jwt_decode(r.token);
              // Update  expiry time
              if (token) {
                r.exp = (token as any).exp ?? 0;
              }          
              // Update new token to store
              commit("setTokenExpiration", r.exp);
              commit("setToken", r.token);
            }
          }
        } catch (e) {
          // Ok to do nothing
          console.log("Failed to refresh token");
        }
      } else if (state.userPartial && state.userPartial.id) {
        commit("clearLogin");
      }
    },
    async cacheUserId({ commit, getters }): Promise<void> {
      if (getters.authUser && getters.authUser.id) commit("setCacheUserId", null);
      else if (getters.user && getters.user.id) commit("setCacheUserId", String(getters.user.id));
    },

    async initialize({ commit, dispatch }): Promise<void> {
      // Bring everything to a known state
      commit("setError", null);
      commit("initStore");
      commit("setReload");
      commit("clearQueryData");
      await dispatch("initTheme");
      // This is to signal to any keepalive components that store data that they should clear it
      return Vue.prototype.$eventBus.$emit("initialize");
    },

    async reloadUserFiles({ commit }, payload: { userId: number }): Promise<Array<AttachmentRecordItem>> {
      return UserfilesService.getDocumentList({ userId: payload.userId, page: 1, pageSize: 50 }).then(r => {
        commit("setUserFiles", r.data ?? []);
        return r.data ?? [];
      });
    },

    async loadCountries(
      { commit },
      payload: { name?: string | undefined; code?: string | undefined }
    ): Promise<CountryRecordItem[] | null> {
      commit("setError", null);
      return StaticService.getCountryList({
        name: payload.name,
        code: payload.code,
        pageSize: 250
      })
        .then(response => {
          commit("setCountries", response?.data || []);
          return response?.data || null;
        })
        .catch((r: ApiError) => {
          commit("setError", r);
          return null;
        });
    },

    async loadCountryStates(
      { commit },
      payload: { id: number }
    ): Promise<Array<StateRecordItem> | null> {
      commit("setError", null);
      commit("setCountryStates", []);
    
      return StaticService.getCountryStatesList({ id: payload.id })
        .then(response => {
          const states = response?.data || []; // Ensure data is extracted properly
          commit("setCountryStates", states);
          return states;
        })
        .catch((error: ApiError) => {
          commit("setError", error);
          return null;
        });
    },

    async loadLanguages(
      { commit },
      payload: { name?: string | undefined; page?: number | undefined; pageSize?: number | undefined }
    ): Promise<Array<LanguageRecordItem> | null> {
      commit("setError", null);
      return StaticService.getLanguageList({
        name: payload.name,
        page: payload.page,
        pageSize: payload.pageSize
      })
        .then(r => {
          const languages = Object.values(r.data || []).map(val => val) || [];
          commit("setLanguages", languages as []);
          return languages;
        })
        .catch((r: ApiError) => {
          commit("setError", r);
          return null;
        });
    },

    async loadTimeZones({ commit }, payload: { name?: string | undefined }): Promise<Record<string, string> | null> {
      commit("setError", null);
      return StaticService.getTimezoneList({
        name: payload.name || ""
      })
        .then(r => {
          commit("setTimeZones", r as Record<string, string>);
          return r as Record<string, string>;
        })
        .catch((r: ApiError) => {
          commit("setError", r);
          return null;
        });
    },

    async msalSignIn({ dispatch }, { app }: { app: OAuthAppsItem }): Promise<string | null> {
      const msalInstance = new PublicClientApplication({
        auth: {
          clientId: app.clientId,
          authority: app.authority
        },
        cache: {
          cacheLocation: "sessionStorage"
        }
      });
      try {
        const request = {
          scopes: ["email"]
        };
        await msalInstance.loginPopup({ scopes: ["email"] });

        const myAccounts = msalInstance.getAllAccounts();
        msalInstance.setActiveAccount(myAccounts[0]);
        // Get a token
        let tokenResponse: AuthenticationResult;

        try {
          tokenResponse = await msalInstance.acquireTokenSilent(request);
        } catch (error) {
          // console.error("Silent token acquisition failed. Using interactive mode", error);
          tokenResponse = await msalInstance.acquireTokenPopup(request);
          // console.log(`Access token acquired via interactive auth `);
        }
        return dispatch("login", { token: tokenResponse.idToken, tokenKey: app.key });
      } catch (error) {
        console.error(`error during authentication: ${error}`);
        return null;
      }
    },
    async getUserTheme({}, userType: number): Promise<string | null> {
      switch(userType) {
        case UserType.EXPERT, UserType.CSR, UserType.PLAN_MANAGER:
          return "onviv";
        case UserType.BLINDED, UserType.VIRTUAL:
          return "insight";
        case UserType.CLIENT, UserType.NETWORK:
          return "cen";
        default:
          return null;
      }
    }
  },
  modules: {
    index,
    query,
    theme,
    terms,
    dashboard,
    reports
  }
});
