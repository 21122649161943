/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssociativeArray } from '../models/AssociativeArray';
import type { CountryList } from '../models/CountryList';
import type { LanguageRecordItem } from '../models/LanguageRecordItem';
import type { meta } from '../models/meta';
import type { StateList } from '../models/StateList';
import { request as __request } from '../core/request';

export class StaticService {

    /**
     * Find Countries.  Filterable by name
     * Returns one or more matching records
     * @result CountryList successful operation
     * @throws ApiError
     */
    public static async getCountryList({
        name,
        code,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records searching by 2 Letter Country Code **/
        code?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<CountryList> {
        const result = await __request({
            method: 'GET',
            path: `/server/static/country/list`,
            query: {
                'name': name,
                'code': code,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find language records.  Filterable by name
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getLanguageList({
        name,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<LanguageRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/static/language/list`,
            query: {
                'name': name,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find States by Country ID
     * Returns one or more matching records
     * @result StateList successful operation
     * @throws ApiError
     */
    public static async getCountryStatesList({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<StateList> {
        const result = await __request({
            method: 'GET',
            path: `/server/static/country/${id}/states`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find Timezones.  Filterable by name
     * Returns one or more matching records
     * @result AssociativeArray successful operation
     * @throws ApiError
     */
    public static async getTimezoneList({
        name,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
    }): Promise<AssociativeArray> {
        const result = await __request({
            method: 'GET',
            path: `/server/static/timezone/list`,
            query: {
                'name': name,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}