<template>
    <div v-if="visible" class="complete-tax-form-popup">
        <div class="d-flex align-center">
            <div class="tax-form-header d-flex">
            <p class="mb-0 d-flex align-center"> <v-icon color="white" class="mx-3">$exlamation</v-icon> Tax Document Missing: Add now to get paid.</p>
            </div>
            <v-spacer></v-spacer>
            <v-btn  v-if="!isMobile" class="complete-form-btn my-2" @click="$eventBus.$emit('showTaxForms'); dismiss()">Complete Forms</v-btn>
            <v-btn class="ms-6 me-3" icon  @click="dismiss()">
                    <v-icon color="white">$close</v-icon>
            </v-btn>
        </div>
        <div v-if="isMobile" class="text-center">
            <v-btn class="complete-form-btn my-2" @click="$eventBus.$emit('showTaxForms'); dismiss()">Complete Forms</v-btn>
        </div>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';


export default Vue.extend({
    data: () => ({

    }),
    props: {
        visible: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        isMobile(): boolean {
            return this.$vuetify.breakpoint.width <= 650;
    },
    },
    methods: {
        dismiss() {
            this.$emit('dismiss-tax-modal');
        }
    }
})
</script>
<style lang="scss" scoped>
.complete-tax-form-popup {
    background-color: var(--v-primary-base);
    position: fixed;
    top: 75px;
    width: 800px;
    max-width: 100%;
    border-radius: 10px;
    z-index: 999999;
    left: 50%;
    transform: translatex(-50%);
}

 p {
    color: white;
}

.complete-form-btn {
    color: var(--v-primary-base);
}

@media (max-width: 650px) {
    .complete-tax-form-popup {
        top: 60px;
        width: calc(100% - 32px);
        left: 16px;
        transform: unset;
    }
}
</style>