import { render, staticRenderFns } from "./VSelectWithValidation.vue?vue&type=template&id=64a7a945&scoped=true"
import script from "./VSelectWithValidation.vue?vue&type=script&lang=ts"
export * from "./VSelectWithValidation.vue?vue&type=script&lang=ts"
import style0 from "./VSelectWithValidation.vue?vue&type=style&index=0&id=64a7a945&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a7a945",
  null
  
)

export default component.exports