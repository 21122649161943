<template>
    <div class="parent-wrapper">
        <p v-if="!showReviewSection" class="black--text mt-3 font-weight-bold">{{ $t('taxForm.w-9.formCaption') }}</p>
        <validation-observer slim ref="observer" v-slot="{ invalid, handleSubmit }">
            <v-form v-if="!showReviewSection">
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h3 class="primary--text">{{ $t('taxForm.captions.nameEntity') }}</h3>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation v-model="w9Form.legalName"
                            placeholder="enterFullName" label="enterFullName" id="fullname" rules="required|name" :maxLength="255">
                        </VTextFieldWithValidation>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTaxEntitySelector v-model="w9Form.entityType" rules="required"  attach
                            placeholder="selectEntity" label="selectEntity">
                        </VTaxEntitySelector>
                    </v-col>
                    <v-col v-if="showOtherEntity || showTaxClassfication" cols="12" md="6"
                        :class="{ 'd-flex align-center': showOwnerShipCheckbox }">
                        <VTextFieldWithValidation v-if="showOtherEntity"
                            v-model="w9Form.entityTypeOther" placeholder="otherEntity" label="otherEntity"
                            rules="required" :maxLength="100"></VTextFieldWithValidation>
                        <VTaxClassificationSelector v-if="showTaxClassfication" attach
                            v-model="w9Form.taxClarification" placeholder="selectTax" label="selectTax">
                        </VTaxClassificationSelector>
                    </v-col>
                  <!-- When entity type is Trust/Estate or Partnership -->
                  <v-col
                    v-if="showOwnerShipCheckbox || isFullWidthEntity" cols="12" md="12">
                    <v-checkbox
                      @change="saveFormData()"
                      v-model="w9Form.ownershipInterest"
                      color="primary"
                      class="black--text black--text custom-label"
                      :label="$t('messages.ownershipContent')"
                    />
                  </v-col>
                  <!-- When entity type is anything else -->
                  <v-col v-else-if="showOwnerShipCheckbox" cols="12" md="6">
                    <v-checkbox
                      @change="saveFormData()"
                      v-model="w9Form.ownershipInterest"
                      color="primary"
                      class="black--text black--text custom-label"
                      :label="$t('messages.ownershipContent')"
                    />
                  </v-col>
                </v-row>
                <v-divider class="mb-6"></v-divider>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h3 class="primary--text">{{ $t('taxForm.captions.permanentAddress') }}</h3>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation v-model="w9Form.address" clearable placeholder="address" label="address"
                            rules="required" :maxLength="255"></VTextFieldWithValidation>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation placeholder="city" clearable v-model="w9Form.city" label="city" :maxLength="100"></VTextFieldWithValidation>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-country-states-selector-with-validation v-model="w9Form.stateId" attach
                            @state-selected="currentState" clearable rules="required" id="state" name="state"
                            placeholder="selectStateTerritory" :showUsStates="true" :countryId="countryId" 
                            label="selectStateTerritory"/>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation v-model="w9Form.zipCode"
                            placeholder="enterZipCode" label="enterZipCode" clearable :readonly="true"
                            @focus="removeReadOnly"
                            rules="required" :maxLength="10">
                        </VTextFieldWithValidation>
                    </v-col>
                </v-row>
                <v-divider class="my-6"></v-divider>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h3 class="primary--text">{{ $t('taxForm.captions.identificationNumberw9') }}</h3>
                    </v-col>
                    <v-col cols="12" md="5">
                        <VTextFieldWithValidation v-model="w9Form.ssn" name="securityNumber"
                            :rules=" w9Form.ein ? '' : 'required'" label="securityNumber" placeholder="securityNumber"
                             :append-icon="showSecurityNumber ? '$eye' : '$eyeSlash'"
                            :type="showSecurityNumber ? 'text' : 'password'"
                            @click:append="showSecurityNumber = !showSecurityNumber" clearable :maxLength="11"
                             />
                    </v-col>
                    <v-col cols="12" md="2" class="d-flex align-center justify-center"><span
                            class="primary--text text-center d-block">OR</span></v-col>
                    <v-col cols="12" md="5">
                        <VTextFieldWithValidation v-model="w9Form.ein" name="employerIdentification"
                            :rules=" w9Form.ssn ? '' : 'required'" label="employerIdentification"
                            placeholder="employerIdentification" clearable 
                            :append-icon="showIdentificationNumber ? '$eye' : '$eyeSlash'"
                            :type="showIdentificationNumber ? 'text' : 'password'"
                            @click:append="showIdentificationNumber = !showIdentificationNumber"
                            :readonly="true"
                            @focus="removeReadOnly"
                            :maxLength="10"
                             />
                    </v-col>
                </v-row>
                <h3 class="primary--text">{{ $t('taxForm.captions.reviewCertification') }}</h3>
                <p class="black--text font-weight-bold">{{ $t('taxForm.captions.reviewCertificationContent') }}</p>
                <div class="text-center">
                    <v-btn color="primary" type="button" :disabled="invalid" @click="showReviewSection = true">{{
                        $t('taxForm.captions.review') }}</v-btn>
                </div>
            </v-form>
            <div v-else>
                <p class="black--text font-weight-bold">Review your W-9 form data and certify to submit to our database
                </p>
                <v-row>
                    <v-col cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">{{ $t('placeholders.enterFullName') }} <v-icon
                                color="primary" class="cursor-pointer ms-1" small
                                @click="showReviewSection = false">$edit</v-icon></p>
                        <p class="black--text mb-0">{{ w9Form.legalName }}</p>
                    </v-col>
                    <v-col cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">{{ $t('fields.cfDefinitionEntityType') }} <v-icon
                                color="primary" class="cursor-pointer ms-1" small
                                @click="showReviewSection = false">$edit</v-icon></p>
                        <p class="black--text mb-0">{{ w9Form.entityType }}</p>
                    </v-col>
                    <v-col cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">{{ $t('account.titles.address') }} <v-icon
                                color="primary" class="cursor-pointer ms-1" small
                                @click="showReviewSection = false">$edit</v-icon></p>
                        <p class="black--text mb-0">{{ w9Form.address }}</p>
                        <p class="black--text mb-0">{{ w9Form.city }} {{ w9Form.state }} {{ w9Form.zipCode }} </p>
                    </v-col>
                    <v-col cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">{{ $t('taxForm.captions.identificationNumberw9') }}
                            <v-icon @click="showReviewSection = false ; w9Form.ein=''; w9Form.ssn=''" color="primary" class="cursor-pointer ms-1"
                                small>$edit</v-icon></p>
                        <p class="black--text mb-0">{{ !showTin ? showMaskValue : showTaxIdentificationNumber }} <v-icon
                                class="ms-2" small @click="showTin = !showTin">{{ !showTin ? '$eye' : '$eyeSlash'
                                }}</v-icon></p>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <p class="black--text font-weight-bold my-4">{{ $t('taxForm.typingName')}}</p>
                <v-list class="ps-0">
                    <v-list-group v-model="openCertificateSection">
                        <template v-slot:activator>
                            <v-list-item-title class=" primary--text font-weight-bold">{{ openCertificateSection ?
                                'Hide Certification Text' : 'View Certification Text'
                                }}</v-list-item-title>
                        </template>
                        <v-list-item class="px-0">
                            <div v-html="$t('taxForm.certificateContent')">

                            </div>
                        </v-list-item>
                    </v-list-group>
                </v-list>
                <v-row>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation v-model="certificateName" placeholder="enterFullName"
                            label="enterFullName" :rules=" invalidNameMatch ? 'required|name|confirmed:fullname' : 'required|name'" :maxLength="255">
                        </VTextFieldWithValidation>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-end align-center"
                        :class="{'justify-center' : isMobile}">
                        <v-btn color="primary" type="submit" :loading="submitLoading" :disabled="!certificateName || invalidNameMatch"
                            @click="submitCertificate()">Certify</v-btn>
                    </v-col>
                </v-row>
            </div>
            <v-overlay absolute :value="overlayLoading">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </validation-observer>

    </div>
</template>
<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue';
import VTextFieldWithValidation from '../VTextFieldWithValidation.vue';
import { ValidationObserver } from "vee-validate";
import ErrorLabel from "@/components/objects/ErrorLabel.vue";
import VTaxEntitySelector from '../VTaxEntitySelector.vue';
import { UserTaxInformationRecordItem, UserService, UserPartialItem } from "@/service";
import VTaxClassificationSelector from '../VTaxClassificationSelector.vue';
import VCountryStatesSelectorWithValidation from '../VCountryStatesSelectorWithValidation.vue';
import { DateTime } from "luxon";
import debounce from "lodash.debounce";

import { RootStoreInterface } from "@/store/types";
import { createHelpers } from "vuex-typescript-interface";


const { mapGetters, mapActions } = createHelpers<RootStoreInterface>();
const getters = mapGetters(["user"]);

const actions = mapActions(["updateTaxFormStatus"]);

export default (Vue as VueConstructor<
    Vue & {
        $refs: {
            observer: InstanceType<typeof ValidationObserver>;
            errorLabel: InstanceType<typeof ErrorLabel>;
        };
    }
>).extend({
    components: {
        ValidationObserver,
        VTextFieldWithValidation,
        VTaxEntitySelector,
        VTaxClassificationSelector,
        VCountryStatesSelectorWithValidation
    },
    data: () => ({
        isInitializing: false,
        submitLoading: false,
        overlayLoading:false,
        showTin: false,
        entityType: "" as UserTaxInformationRecordItem.entityType,
        taxClarification: "" as UserTaxInformationRecordItem.taxClarification,
        showOtherEntity: false,
        showTaxClassfication: false,
        showOwnerShipCheckbox: false,
        countryId: 231,
        certificateName: "",
        showSecurityNumber: false,
        showIdentificationNumber: false,
        loading: false,
        openCertificateSection: false,
        recordId: undefined as number | undefined,
        showReviewSection: false,
        fetchRunning: false,
        reload: (null as unknown) as () => void,
        w9Form: {
            id: undefined as number | undefined,
            legalName: "",
            formType: UserTaxInformationRecordItem.formType.W_9_FORM,
            ownershipInterest: false,
            entityType: "" as UserTaxInformationRecordItem.entityType,
            taxClarification: "" as UserTaxInformationRecordItem.taxClarification,
            stateId: undefined as number | undefined,
            securityNumber: "",
            identificationNumber: "",
            city: "",
            state: "",
            entityTypeOther: "",
            address: "",
            ssn: "",
            ein: "",
            zipCode: undefined as number | undefined,
        } as UserTaxInformationRecordItem
    }),
    props: {
        prefilledRes: {
            type: Object as PropType<UserTaxInformationRecordItem>,
            default: () => ({} as UserTaxInformationRecordItem),
        },
        showW9Form: {
            type: Boolean,
            default: false
        },
        parentLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...getters,
        userId(): number {
            return this.user?.id || 0;
        },
        showMaskValue(): string {
            if(this.w9Form.ssn) {
                return '*'.repeat(this.w9Form.ssn.toString().length);
            } else if (this.w9Form.ein) {
                return '*'.repeat(this.w9Form.ein.toString().length);
            } else {
                return '';
            }
        },
        invalidNameMatch() {
            return this.w9Form.legalName == this.certificateName ? false : true;  
        },
        showTaxIdentificationNumber() {
            return this.w9Form?.ssn?.length ? this.w9Form.ssn : this.w9Form.ein;
        },
        hasTaxidentificationNumber() {
            return this.w9Form?.ssn?.length ||  this.w9Form?.ein?.length
        },
        isMobile(): boolean {
        return this.$vuetify.breakpoint.smAndDown;
        },
        taxFormNotStarted(): boolean {
            return this.user?.taxFormStatus == UserPartialItem.taxFormStatus.NOT_STARTED || this.user?.taxFormStatus == UserPartialItem.taxFormStatus.IN_PROGRESS;
        },
        isFullWidthEntity(): boolean {
          const entity = this.w9Form.entityType;
          return [
            UserTaxInformationRecordItem.entityType.TRUST_ESTATE,
            UserTaxInformationRecordItem.entityType.PARTNERSHIP
          ].includes(entity);
        }
    },
    methods: {
        ...actions,
        updateFormData(response: UserTaxInformationRecordItem) {
            this.isInitializing = true;
            this.w9Form.countryId = 231,
            this.w9Form.id = response?.id ? response.id : undefined;
            this.w9Form.legalName = response?.legalName ? response.legalName : '';
            this.w9Form.entityType = response?.entityType ? response.entityType : undefined ;
            this.w9Form.taxClarification = response?.taxClarification ? response.taxClarification : undefined;
            this.w9Form.stateId = response?.stateId ? response.stateId : undefined;
            this.w9Form.city = response?.city ? response.city : '';
            this.w9Form.entityTypeOther = response?.entityTypeOther ? response.entityTypeOther : undefined;
            this.w9Form.address = response?.address ? response.address : '';
            this.w9Form.state = response?.state ? response.state : '';
            this.w9Form.zipCode = response?.zipCode ?  response.zipCode : '';
            this.w9Form.ssn = response?.ssn ? response.ssn : undefined;
            this.w9Form.ein = response?.ein ? response.ein : undefined;
            this.w9Form.ownershipInterest = response.ownershipInterest ? true : false;

            this.$nextTick(() => {
            this.isInitializing = false;
        });
        },
        async createFromData() {
            this.loading = true;
            this.fetchRunning = true;
            this.w9Form.countryId = undefined;
            const payload = this.w9Form;
            const id = this.userId;
            try {
                const res = await UserService.createTaxInformation({ id: id as number, requestBody: payload });
                if (res) this.w9Form.id = res.id;
            } catch (e) {
                console.log('error', e);
            }
            this.fetchRunning = false;
            this.loading = false;
        },
        updateTaxFrom(formData: UserTaxInformationRecordItem) {
            this.loading = true;
            this.fetchRunning = true
            this.w9Form.countryId = undefined;

            const payload = formData;

            // remove certify date on edits
            payload.certifyDate = null;

            const id = this.userId as number;
            try {
                UserService.updateTaxInformation({ id: id, requestBody: payload });
            } catch (e) {
                console.log('error', e);
            }
            this.loading = false;
            this.fetchRunning = false;
        },
        trimAddressPayload() {
            this.w9Form.city = this.w9Form.city ? this.w9Form.city?.trim() : '';
            this.w9Form.address = this.w9Form.address ? this.w9Form.address?.trim() : '';
            this.w9Form.zipCode = this.w9Form.zipCode ? this.w9Form.zipCode?.trim() : '';
            if(!this.w9Form.stateId) this.w9Form.state = '';
        },
        saveFormData() {
            if(this.fetchRunning) return;
            if(this.parentLoading) return;

            // trim causing double submit - remove
            // this.trimAddressPayload();

            if (!this.w9Form.id) {
                this.createFromData();
            } else {
                this.updateTaxFrom(this.w9Form);
            }
        },
        async submitCertificate() {
            this.submitLoading = true;
            if (!this.w9Form.certificationDate) this.w9Form.certificationDate = this.formatDateTimeRequirement(Math.floor(Date.now() / 1000));
            const payload = this.w9Form;
            const id = this.userId as number;
            try {
               await UserService.updateTaxInformation({ id: id, requestBody: payload });

              const userRes =  await UserService.getUserById({ id: id });

                // update tax form status in global storage: local storage and FE user partial item
              if(this.taxFormNotStarted) this.updateTaxFormStatus(userRes.taxFormStatus)
             


                this.$emit('submit-modal');
            this.$confirm(`Your W-9 Form has been successfully submitted. Your information has been stored and your next payment will be issued per our standard payment processing timelines.`, { icon: "", title: `W-9 Form Submitted`, buttonTrueText: '', buttonFalseText: '' });
            } catch (e) {
                console.log('error', e);
            }
            this.submitLoading = false;
            
      },
      formatDateTimeRequirement(timestamp: number) {
            let d = DateTime.fromSeconds(timestamp);
            return d.toFormat("yyyy-MM-dd");
        },
      currentState(val: any) {
        if(!val) this.w9Form.state = '';
        this.w9Form.state = val.text;
      },
      removeReadOnly(event: any) {
    event.target.removeAttribute('readonly');
  }
    },
    watch: {
        'w9Form.entityType'(newVal) {
            this.showOtherEntity = newVal === UserTaxInformationRecordItem.entityType.OTHER;
            this.showTaxClassfication = newVal === UserTaxInformationRecordItem.entityType.LLC;
            this.showOwnerShipCheckbox = [UserTaxInformationRecordItem.entityType.TRUST_ESTATE, UserTaxInformationRecordItem.entityType.PARTNERSHIP].includes(newVal);
        },

        'w9Form.taxClarification'(newVal) {
            this.showOwnerShipCheckbox = newVal === UserTaxInformationRecordItem.taxClarification.PARTNERSHIP;
        },
        w9Form: {
            handler(newVal) {
                if (newVal) {
                    if (this.reload && !this.isInitializing) this.reload();
                }
            },
            deep: true,
        },
        showW9Form: {
            handler(newVal) {
                if (newVal) this.updateFormData(this.prefilledRes);
            },
            immediate: true,
        },
        prefilledRes(newVal) {
            if (newVal) {
                this.updateFormData(newVal);
            }
        }
    },
    mounted() {
    this.updateFormData(this.prefilledRes);
    this.reload = debounce(this.saveFormData, 1000, { leading: false });
  }
    // mounted() {
    //     // this.fetchFormData();
    // }
})
</script>
<style lang="scss" scoped>
:deep(.v-list), .v-list {
    background-color: white !important;
}

:deep(.v-list-item) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.parent-wrapper {
    position: relative;
}

</style>