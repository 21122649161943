<template>
  <v-autocomplete-with-validation v-on="$listeners"  @input="emitSelectedState" :items="filteredStates" :label="label" v-bind="$attrs" />
</template>

<script lang="ts">
import Vue from "vue";
import VAutocompleteWithValidation from "./VAutocompleteWithValidation.vue";
import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";

const { mapGetters, mapActions } = createHelpers<RootStoreInterface>();

const getters = mapGetters(["countryStates"]);
const actions = mapActions(["loadCountryStates"]);

export default Vue.extend({
  components: {
    VAutocompleteWithValidation
  },
  props: {
    countryId: {
      type: Number,
      required: true
    },
    showUsStates: {
      type: Boolean,
      default: false
    },
    passStringValue: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      default: 'state'
    }
  },
  computed: {
    ...getters,
    filteredStates(): Array<{ value: number|string; text: string }> {
    return (this.countryStates || [])
      .filter((state) => state.countryId === this.countryId && state.id !== undefined && state.name !== undefined) // Ensure defined values
      .map((state) => ({
        value: !this.passStringValue ? state.id as number : state.name as string, // Type assertion to avoid undefined issue
        text: state.name as string, // Type assertion to avoid undefined issue
      }));
  },
  },
  methods: {
    ...actions,
    async load() {
      const countryId = this.showUsStates ? 231 : this.countryId;
      // Retrive from server only once
      try {
        const res = await this.loadCountryStates({ id: countryId });
        (!res?.length) ? this.$emit('no-states-available') : this.$emit('states-availabe');
      } catch (e) {
        console.log(e);
        // TODO: handle api error here
      }
    },
    emitSelectedState(value: number | string) {
      const selectedState = this.filteredStates.find((state) => state.value === value);
      this.$emit("state-selected", selectedState || null);
    }
  },
  watch: {
    countryId: "load"
  },
  created() {
   if(this.countryId) this.load();
  }
});
</script>
