/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * User Registration Data
 */
export type UserRegistrationInput = {
    /**
     * User first name
     */
    firstName: string;
    /**
     * User last name
     */
    lastName: string;
    /**
     * Email Address
     */
    email: string;
    /**
     * User's Country
     */
    countryCode: string;
    /**
     * User's State
     */
    state: string;
    /**
     * User's City
     */
    city: string;
    /**
     * User password - Defined, not returned
     */
    password: string;
    /**
     * User password confirm - Defined, not returned
     */
    passwordConfirm: string;
    /**
     * User Specialty
     */
    specialty: UserRegistrationInput.specialty;
    /**
     * User Other Specialty
     */
    otherSpecialty?: string;
    /**
     * ID of related invitation if applicable
     */
    invitationId?: string;
}

export namespace UserRegistrationInput {

    /**
     * User Specialty
     */
    export enum specialty {
        MEDONC = 'medonc',
        SURGONC = 'surgonc',
        RADONC = 'radonc',
        PATH = 'path',
        RADIOLOGY = 'radiology',
        OTHER = 'other',
    }


}
