import { UserType } from "@/config";

// User Invitations
export default [
  // User Invitations
  {
    path: "/admin/invitations",
    name: "InvitationIndex",
    component: () => import(/* webpackChunkName: "invitations" */ "@/views/admin/invitations/InvitationIndex.vue"),
    meta: { allowedUsers: [UserType.CSR] },
    children: [
      {
        name: "InvitationForm",
        path: ":inviteId",
        component: () => import(/* webpackChunkName: "invitations" */ "@/views/admin/invitations/InvitationForm.vue"),
        props: true
      }
    ]
  },
  {
    name: "InvitedRegistration",
    path: "/physician/register/:uuid",
    component: () => import(/* webpackChunkName: "register" */ "@/views/register/Register.vue"),
    props: true,
    meta: {
      disallowAuthed: true,
      appClass: "appModeLogin",
      appBar: "none"
    }
  },
  {
    name: "OrganicRegistration",
    path: "/register/:slug/:extra?",
    component: () => import(/* webpackChunkName: "register" */ "@/views/register/Register.vue"),
    props: true,
    meta: {
      disallowAuthed: true,
      appClass: "appModeLogin",
      appBar: "none",
      hasExpertRegistration: true
    }
  }
];
