<template>
  <v-app :class="appClass" :style="appStyle">
    <!-- <user-navigation-drawer v-if="navDrawer" /> -->
    <admin-menu v-if="isCsr" :open.sync="isAdminMenuOpen" id="adminMenu" :specificity= "isDraftModalOpen" />
    <!-- Display user menu in navigation drawer for mobile web-->

    <user-menu v-if="mobile && !smOnly" :open.sync="isUserMenuOpen" @tax-form-open="resetFormPopupState()" :showACtionAvatar="false" id="userMenuSm" :specificity= "isDraftModalOpen"/>

    <!-- global tax-form notification -->
    <CompleteTaxForm v-if="visibleCompleteTaxFormPopup" @dismiss-tax-modal="showTaxNotification = false"></CompleteTaxForm>
    <TaxFormLayout :isSessionTimeout="showSessionTimeout" @tax-layout-open="showTaxNotification = false"></TaxFormLayout>
    
    <v-app-bar v-if="routeMeta.appBar !== 'none'" app color="white" :class="isDraftModalOpen ? 'prioritize-specificity': ''" ark clipped-left clipped-right x-flat>
      <v-btn
        v-if="$route.name && mobile && canBack"
        class="back-btn"
        min-width="0"
        height="32px"
        color="#DED3E8"
        @click="backPressed()"
      >
        <v-icon color="primary">$back</v-icon>
      </v-btn>
      <div v-else class="d-flex align-center">
        <router-link :to="{ name: 'Home' }" id="cenHome">
          <app-bar-logo />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <!-- Display user menu as dropdown menu for desktop web-->
      <language-selection-icon />
      <v-icon v-if="mobile && isDiscussionScreen" class="mr-2" color="primary" @click="$help('help-query-index')">$help</v-icon>
      <user-menu  @toggle-menu-mb=" toggleUserMenu()" @tax-form-open="resetFormPopupState()"  id="userMenu"/>
      <v-app-bar-nav-icon v-if="isCsr" id="btnAdminMenu" :class="{'ml-2' : !xsMobile}" color="primary" @click="toggleAdminMenu()" />
    </v-app-bar>
    <v-main>
      <transition name="fade" mode="out-in">
        <!-- Cache QueryIndex so we save our place -->
        <keep-alive :include="['QueryIndex']">
          <router-view></router-view>
        </keep-alive>
      </transition>
    </v-main>

    <!-- Global snackbar -->
    <v-snackbar v-model="showSnackbar" content-class="text-center t-3" :timeout="3000" :color="snackbarCol">
      {{ snackbarText }}
      <template v-if="canCloseSnackbar" v-slot:action="{ attrs }">
        <v-btn color="white" icon text dense v-bind="attrs" @click="showSnackbar = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>


    <!-- Global Help Dialog -->
    <help v-if="helpKey && showHelp" v-model="showHelp" :helpKey="helpKey"/>
    <session-timeout-dialog v-model="showSessionTimeout" :expiryTime="tokenExpiration"/>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import { Route } from "vue-router";
import LanguageSelectionIcon from "@/components/layout/LanguageSelectIcon.vue";
import AdminMenu from "@/components/layout/AdminMenu.vue";
import UserMenu from "@/components/layout/UserMenu.vue";
import AppBarLogo from "@/components/theme/AppBarLogo.vue";
import Help from "@/components/util/Help.vue";
import SessionTimeoutDialog from "@/components/dialog/SessionTimeoutDialog.vue";
import CompleteTaxForm from "./components/util/CompleteTaxForm.vue";
import TaxFormLayout from "./components/dialog/TaxFormLayout.vue";

import { UserPartialItem, UserService } from "./service";

import type { RouteMeta } from "@/router";
import { UserType } from "@/config";

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";

import { ThemeStoreInterface } from "@/store/theme/types";
const { mapGetters: mapThemeGetters } = createHelpers<ThemeStoreInterface>();
const themeGetters = mapThemeGetters(["accentImage", "nameText"]);

const { mapGetters, mapMutations, mapActions } = createHelpers<RootStoreInterface>();

const getters = mapGetters(["isLoggedIn", "user", "snackbar", "snackbarColor", "snackbarCanClose", "isAdminMenuOpen", "tokenExpiration", "apiToken", "redirectRoute", "cachedUserId", "authUser", "isDraftModalOpen"]);
const mutations = mapMutations(["setSnackbar", "setAdminMenu", "setError", "setRedirectRoute", "setTaxNotificationValue"]);
const actions = mapActions(["initialize", "resetCredentials", "refreshToken"]);

export default Vue.extend({
  name: "App",

  components: {
    LanguageSelectionIcon,
    AdminMenu,
    UserMenu,
    AppBarLogo,
    Help,
    SessionTimeoutDialog,
    CompleteTaxForm,
    TaxFormLayout
  },
  data: () => ({
    showTaxNotification: false,
    showSnackbar: false,
    snackbarText: "",
    snackbarCol: undefined as string|undefined,
    canCloseSnackbar: false,
    showHelp: false,
    helpKey: "",
    sessionTimer: null as NodeJS.Timeout | null,
    meta: {}, // To update route meta dynamically,
    isUserMenuOpen: false,
    layoutTitle: "",
    showSessionTimeout: true,
    prioritizeSpecificity: false
  }),
  computed: {
    ...getters,
    ...themeGetters,
    routeMeta(): RouteMeta {
      return this.$route.matched.length ? this.$route.matched[0].meta : {};
    },
    isCsr(): boolean {
      return this.isLoggedIn && this.user?.userType == UserType.CSR;
    },
    isExpert(): boolean {
      return this.isLoggedIn && this.user?.userType == UserType.EXPERT;
    },
    mobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.smAndDown;
    },
    smOnly(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.smOnly;
    },
    xsMobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.xsOnly;
    },
    appClass(): string {
      return this.routeMeta.appClass ?? 'background';
    },
    appStyle(): Record<string, string> {
      if (this.routeMeta.appClass === 'appModeLogin' /*&& !this.mobile */) {
        return { 'background-image': `url("${this.accentImage}") !important` };
      }
      return {};
    },
    thisYear(): string {
        return (new Date()).getFullYear().toString();
    },
    isDiscussionScreen(): boolean {
      return this.$route.path  === '/discussions' ?? false
    },
    isQueryDetailScreen(): boolean {
      return  this.$route.name  === 'QueryDetail' ?? false
    },
    isTokenExpired(): boolean {
      return this.tokenExpiration < Date.now() / 1000;
    },
    taxFormNotStarted(): boolean {
      return this.user?.taxFormStatus == UserPartialItem.taxFormStatus.NOT_STARTED || this.user?.taxFormStatus == UserPartialItem.taxFormStatus.IN_PROGRESS;
    },
    isNoeticExpert(): boolean {
      return this.user?.noeticExpert ? true : false
    },
    visibleCompleteTaxFormPopup(): boolean {
      return !this.isCsr && this.isLoggedIn && this.isNoeticExpert && this.showTaxNotification;
    },
    canBack(): boolean {
      // const pathNames = ["/", "/member/lobby", "/admin/queries", "/dashbaord", "/biometricAuth"]
      const routeNames = ["Home", "MemberLobby", "QueryIndex", "BiometricAuth"]
      if (this.$route.name)
        return !routeNames.includes(this.$route.name);
      return (this.isDiscussionScreen || this.isQueryDetailScreen);
    }
  },
  methods: {
    ...mutations,
    ...actions,
    toggleAdminMenu() {
      this.setAdminMenu(!this.isAdminMenuOpen);
    },
    toggleUserMenu() {
      this.isUserMenuOpen =  !this.isUserMenuOpen;
    },
    help(helpKey: string): void {
      this.helpKey = helpKey;
      this.showHelp = true;
    },
    updateMatomoSettings() {
      const _paq = window._paq || [];
      if (this.isLoggedIn && this.user) {
        _paq.push(['setUserId', `${this.user.id}`]);
        _paq.push(['setCustomDimension', 1, `${this.user.userType}`]);
        _paq.push(['setCustomDimension', 2, `${this.user.noeticExpert}`]);
      } else {
        _paq.push(['resetUserId']);
        _paq.push(['deleteCustomDimension', 1]);
        _paq.push(['deleteCustomDimension', 2]);
      }
      _paq.push(['trackPageView']);
    },
    updateSessionTimer() {
      // Check if session is expired or not. If yes clear session data.
      if (this.isLoggedIn) {
        this.clearSessionTimer();
        if (this.tokenExpiration > Date.now() / 1000) {
          const timeRemaining = this.tokenExpiration - (Date.now() / 1000);
          this.sessionTimer = setTimeout(async () => {
            // Update token from local storage.
            await this.refreshToken();
            this.$nextTick(async () => {
              if (this.isTokenExpired) {
                this.showSessionTimeout = false;
                if (this.isLoggedIn) {
                  // Set redirection url.
                  if (this.user && this.user.id) {
                    this.setRedirectRoute({ [`${this.user.id}`]: Object.assign({}, this.$route) });
                  }
                  // Clear session data.
                  await this.resetCredentials();
                  await this.initialize();
                  this.setError("Session expired.");
                }
              }
            });
          },
          timeRemaining * 1000);
        }
      } else {
        this.clearSessionTimer();
      }
    },
    clearSessionTimer() {
      this.showSessionTimeout = false;
      if (this.sessionTimer) {
        clearTimeout(this.sessionTimer);
        this.sessionTimer = null;
      }
    },
    backPressed() {
      this.$router.back()
    },
    onTabChanged() {
      if (!document.hidden) {
        this.refreshToken();
      }
    },
    async  fetchUserTaxDetails(){
          if(this.taxFormNotStarted) this.showTaxNotification = true;


            // const  id = this.user?.id || 0;
            // try{
            //      const res = await UserService.getUserTaxInformationByUId({id: id});
                
            //   if(!res.certificationDate)  this.showTaxNotification = true
            // } catch(e) {
            //     this.showTaxNotification = true
            //     console.log('error', e);
            // }
        },
        resetFormPopupState() {
          this.showTaxNotification = false;
        }
  },
  // Since the App class is always present, this is a good place to handle global watches
  watch: {
    // If the store causes a log out, redirect to login page.
    // This could also route on login based on user type, rather than the
    // route in the LoginCard
    isLoggedIn(newValue, oldValue) {
      if (oldValue != newValue) {
        if (newValue) {
          let homeRoute: string | null = "/";
          if (this.redirectRoute) {
            // Set redirect route if available
            let route = this.redirectRoute['guest'] || this.redirectRoute[`${this.user?.id}`] || null;
            if (route && route.name) {
              this.$router.replace({ name: route.name, path: route.path, params: route.params, query: route.query, hash: route.hash }).catch(() => {/* Ignore redirection error */});
              // Clear redirect route
              this.setRedirectRoute(null);
              homeRoute = null;
            } 
          }
          if (homeRoute) this.$router.replace(homeRoute).catch(() => {/* Ignore redirection error */});
        } else {
          this.$router.replace("/login").catch(() => {/* Ignore redirection error */});
        }
      }
      this.updateMatomoSettings();
      this.updateSessionTimer();
    },
    snackbar(newValue) {
      if (newValue != "") {
        this.snackbarText = newValue;
        this.snackbarCol = this.snackbarColor;
        this.showSnackbar = true;
        this.canCloseSnackbar = this.snackbarCanClose;
        this.setSnackbar("");
      }
    },
    routeMeta(val) {
      this.meta = val;
    },
    apiToken(newValue, oldValue) {
      if ((oldValue && newValue) || !newValue) {
        // Reset timer if token is updated
        this.updateSessionTimer();
      }
    },
    $route(to: Route, from: Route) {
      this.isUserMenuOpen = false;
      let draftId = this.$route.query["draftId"];

      if ((to.name == "NewQueryProxy" || to.name == "NewQuery") && !draftId) {
        this.prioritizeSpecificity = true;
      } else if (from.name == "NewQueryProxy" || from.name == "NewQuery") {
        this.prioritizeSpecificity = false;

      }

      if (to) {
        if (to.name === 'Login') this.$eventBus.$emit("dismiss");
        else {
          this.layoutTitle = to.meta?.title ??  "";
        }
      } else {
        this.layoutTitle = "";
      }
    },
    cachedUserId(val: string | null) {
      if (val && val.length) {
        this.setRedirectRoute({ [val]: Object.assign({}, this.$route) });
      }
    },
    isExpert(newVal) {
      if(newVal) {
        this.fetchUserTaxDetails();
      }
    },
    showTaxNotification(newVal) {
      this.setTaxNotificationValue(newVal);
    },
    taxFormNotStarted(newVal){
      newVal ? this.showTaxNotification = true : this.showTaxNotification = false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.title = `${this.nameText}`;
    });

    let draftId = this.$route.query["draftId"];
    const routeName = this.$route.name;
    if ((routeName == "NewQueryProxy" || routeName == "NewQuery") && !draftId) {
      this.prioritizeSpecificity = true;
    }
    if(this.isExpert) this.fetchUserTaxDetails();
  },
  created() {
    this.$eventBus.$on("help", this.help);
    this.$eventBus.$on("snackbar", (x: any) => {
        this.setSnackbar(x);
    });
    window.addEventListener("visibilitychange", this.onTabChanged, true);
    this.updateSessionTimer();
  },
  beforeDestroy() {
    this.$eventBus.$off("help", this.help);
    window.removeEventListener("visibilitychange", this.onTabChanged, true);
    this.clearSessionTimer();
  }
});
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
@import "@/scss/global.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

// Not scoped, for now
.v-application {
  // background: rgba(234, 234, 234, 1) !important;
  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(255, 255, 255, 1) 50%,
  //   rgba(234, 234, 234, 1) 100%
  // ) !important;

  &.background {
    background-color: var(--v-background-base) !important;
  }

  &.appModeUser {
    background: rgba(255, 255, 255, 1) !important;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(234, 234, 234, 1) 100%
    ) !important;
  }
  &.appModeLogin {
    // background-image: url("~@/assets/footer.png") !important;
    background-repeat: no-repeat !important;
    background-size: auto 250px !important;
    background-attachment: fixed !important;
    background-position: bottom right !important;
  }

  &.appModeUserRightNoFooter {
    background-image: url("~@/assets/CEN-App-Concept-right.png") !important;
    background-repeat: no-repeat !important;
    background-size: 500px 275px !important;
    background-attachment: fixed !important;
    background-position: top right !important;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    &.appModeUserRightNoFooter {
      background-size: 75% 100px !important;
    }
  }

  .home-app-bar {
    .v-btn:before {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  .login-footer-wrap {
    // Space to accomodate the absolute div so it doesnt overlap
    height: 70px;

    .login-footer {
      position: absolute;
      bottom: 14px;
      left: 0;
      right: 0;
      // display: flex;
      // flex-direction: row;
      // justify-content: space-around;
    }

    &.mobile {
      height: inherit;

      // .row {
      //   width: 100%;

      //   .login-footer {
      //     position: relative;
      //     left: inherit;
      //     right: inherit;
      //     margin: 0 auto;
      //   }
      // }
    }
  }

  .app-bar-title {
    position: absolute;
    text-align: center;
    width: calc(100% - 32px);
  }
}
</style>

<style lang="scss">
// Global classes should be minimized but they can go here

// For hiding scroll bar visible in windows machine
html {
  overflow-y: auto;
}
.text-black {
  color: rgba(0, 0, 0, 0.87);
}
.discussion-header-title {
  font-size: 18px;
}
.discussion-header-subtitle {
  font-size: 10px;
  font-weight: normal;
  margin-top: auto;
  margin-bottom: auto;
}
.discussion-header-caption {
  font-size: 24px;
  font-weight: 300;
}

.discussion-header-subcaption {
  font-size: 18px;
  font-weight: 300;
}

.layout-header-title {
  font-size: 24px;
  font-weight: 700;
}

.discussion-header-subcaption-large {
  font-size: 20px;
  font-weight: 300;  
}

.discussion-text {
  font-size: 14px;
  font-weight: normal;

  .text-small {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.discussion-text-wrap {
  white-space: pre-wrap;
}

.discussion-focus-text {
  font-size: 12px;
  font-weight: normal;
}

.discussion-datetime-text {
  font-size: 12px;
  font-weight: normal;
}

.discussion-footer-text {
  font-size: 10px;
  font-weight: 300;
  margin-top: 6px;
}

.discussion-submission-text {
  font-size: 14px;
  font-weight: 300;
  margin-top: 6px;

  .text-small {
    font-size: 10px;
  }
}

.discussion-small {
  .discussion-header-caption {
    font-size: 18px;
  }

  .discussion-header-subcaption {
    font-size: 14px;
  }
}
// .discussion-cme-title {
// }

// .discussion-cme-text {
// }

// .discussion-response-user-text {
// }

.truncate {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.theme--light {
  .v-text-field:not(.v-input--is-focused, .v-input--is-dirty, .v-input--is-disabled, .error--text) {
    .v-label:not(.v-label--is-disabled, .error--text) {
      color: rgba(0, 0, 0, 0.8) !important;
    }
    .v-input__control>.v-input__slot:before, .v-input__control>.v-select__slot:before {
      border-color: rgba(0, 0, 0, 0.8) !important;
    }
  }
  .v-text-field.v-input--is-label-active:is(.v-input--is-dirty, .success--text):not(.v-input--is-disabled) {
    .v-label:not(.v-label--is-disabled, .error--text) {
      color: var(--v-primary-base) !important;
    }
    .v-input__control>.v-input__slot:before, .v-input__control>.v-select__slot:before {
      border-color: var(--v-primary-base) !important;
    }
  }
  .v-input:not(.v-input--is-focused, .v-input--is-dirty, .v-input--is-disabled) {
    .v-icon:not(.error--text) {
      color: rgba(0, 0, 0, 0.64) !important;
    }
    .v-radio.v-radio--is-disabled, .v-input.v-input--is-disabled {
      .v-icon {
        color: rgba(0, 0, 0, .38) !important;
      }
    }
  }
  .v-input:is(.v-input--is-dirty, .success--text):not(.v-input--is-disabled) {
    .v-icon:not(.error--text) {
      color: var(--v-primary-base) !important;
    }
    .v-radio.v-radio--is-disabled, .v-input.v-input--is-disabled {
      .v-icon {
        color: rgba(0, 0, 0, .38) !important;
      }
    }
  }
}

.pool-filter-item {
    .v-text-field__details {
        display: none;
    }
}

//for handling specificity of header
.prioritize-specificity {
  z-index: 999 !important;
}

// for attachment-modal update-ui
.attachment-content .prismic-terms p {
    margin-bottom: 0;
}

.attachment-checkbox i.v-icon.notranslate.far.fa-square.theme--light {
  color: var(--v-primary-base) !important;
}

// global styling for collapse left column view
.icon-collapse-wrapper {
  position: fixed;

  &::after {
    content: "";
    position: absolute;
    top: 127%;
    width: 2px;
    height: calc(98vh - 108px);
    background-color: #acaeb1;
    z-index: 2;
  }
}

.collapse-chevron-icon, .collapse-chevron-icon button {
  height: 26px !important;
  width: 26px !important;
  font-size: 26px !important;
}
</style>
