<template>
  <v-autocomplete-with-validation v-model="selectedItem"  @input="emitCountryState" :items="itemsForSelect" :label="label" v-bind="$attrs" v-on="$listeners">
  </v-autocomplete-with-validation>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import VAutocompleteWithValidation from "./VAutocompleteWithValidation.vue";
import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";
import { CountryRecordItem } from "@/service";

const { mapGetters, mapActions } = createHelpers<RootStoreInterface>();

const getters = mapGetters(["countries"]);
const actions = mapActions(["loadCountries"]);

export default Vue.extend({
  components: {
    VAutocompleteWithValidation
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: [Number, String, Object], // for countryId / certificationCountry
      default: null
    },
    passStringValue: {
      type: Boolean,
      required: false,
      default: false
    },
    preSelectedCountry: {
      type: [Number, Object] as PropType<number | CountryRecordItem | null>,
      required: false,
      default: null
    },
    label: {
      type: String,
      default: 'country'
    }
  },
  computed: {
    ...getters,
    itemsForSelect(): Array<{ value: number|CountryRecordItem; text: string }> {
    return (this.countries || [])
      .filter((country) => country.id !== undefined && country.countryName !== undefined)
      .map((country) => ({
        value: !this.passStringValue ? country.id as number : country as CountryRecordItem,
        text: country.countryName as string,
      }));
  },
  },
  data:() => ({
    selectedItem: undefined as { value: number; text: string } | undefined
  }),
  methods: {
    ...actions,
    async load() {
      // Retrieve from server only once
      if (!this.countries) {
        try {
          await this.loadCountries({});
        } catch (e) {
          console.log(e);
          // TODO: handle api error here
        }
      }
    },
    emitCountryState(value: number | string) {
      const selectedState = this.itemsForSelect.find((country) => country.value === value);
      this.$emit("country-selected", selectedState || null);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (!newVal) return;
        const match = this.itemsForSelect.find((item) => item.value === newVal);
        if (match) {
          this.selectedItem = match;
          this.$emit("country-selected", match); 
        }
      }
    },
    preSelectedCountry: {
      immediate: true,
      handler(newVal) {
        if (newVal && typeof newVal === 'object' && 'countryName' in newVal) {
          this.selectedItem = this.itemsForSelect.find(
            (item) => item.text === newVal.countryName
          );
        }
      }
    }
  },
  created() {
    this.load();
  }
});
</script>

<style lang="scss" scoped>
:deep(.v-autocomplete) {
  .v-chip {
    border-radius: 12px;
    font-size: 12px;
    height: 24px;
    background-color: var(--v-primary-base) !important;
    border-color: var(--v-primary-base) !important;
    color: #ffffff;

    .v-chip__content {
      display: inline-block !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 2px;
      padding-right: 14px;
    }
    .v-chip__close {
      position: absolute;
      top: 2.5px;
      right: 9px;
      width: 24px;
      color: #ffffff;
    }
  }
}
</style>
