/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * User Tax Information
 */
export type UserTaxInformationRecordItem = {
    /**
     * User Tax Unique ID
     */
    id?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * User ID
     */
    userId?: number;
    /**
     * Country ID
     */
    countryId?: number;
    /**
     * State ID
     */
    stateId?: number;
    /**
     * Certification Country
     */
    certificationCountry?: number;
    /**
     * Form Type
     */
    formType?: UserTaxInformationRecordItem.formType;
    /**
     * Legal Name
     */
    legalName?: string;
    /**
     * Entity Type
     */
    entityType?: UserTaxInformationRecordItem.entityType;
    /**
     * Entity Type Other
     */
    entityTypeOther?: string;
    /**
     * Tax Clarification
     */
    taxClarification?: UserTaxInformationRecordItem.taxClarification;
    /**
     * Flag for show if it is Ownership Interest
     */
    ownershipInterest?: boolean;
    /**
     * Address
     */
    address?: string;
    /**
     * City
     */
    city?: string;
    /**
     * State
     */
    state?: string;
    /**
     * Zip Code
     */
    zipCode?: string;
    /**
     * User SSN
     */
    ssn?: string;
    /**
     * User EIN
     */
    ein?: string;
    /**
     * Individual Tax Identification Number
     */
    itin?: string;
    /**
     * Foreign Tax Identification Number
     */
    ftin?: string;
    /**
     * Flag of required FTIN
     */
    ftinNotRequired?: boolean;
    /**
     * Date of birth
     */
    dob?: string;
    /**
     * Certification date
     */
    certificationDate?: string;
    /**
     * fFlag for Paypal
     */
    doNotHavePaypal?: boolean;
    /**
     * Paypal Email
     */
    paypalEmail?: string;
    /**
     * Personal Phone Number
     */
    personalPhoneNumber?: string;
    /**
     * Bank Name
     */
    bankName?: string;
    /**
     * Bank IBAN
     */
    bankIban?: string;
    /**
     * Signature
     */
    signature?: string;
    /**
     * Flag for migrated
     */
    migrated?: boolean;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace UserTaxInformationRecordItem {

    /**
     * Form Type
     */
    export enum formType {
        W_9_FORM = 'W-9 Form',
        W_8_BEN_FORM = 'W-8 BEN Form',
    }

    /**
     * Entity Type
     */
    export enum entityType {
        INDIVIDUAL_SOLE_PROPRIETOR = 'Individual/Sole Proprietor',
        C_CORPORATION = 'C Corporation',
        S_CORPORATION = 'S Corporation',
        PARTNERSHIP = 'Partnership',
        TRUST_ESTATE = 'Trust/Estate',
        LLC = 'LLC',
        OTHER = 'Other',
    }

    /**
     * Tax Clarification
     */
    export enum taxClarification {
        C_CORPORATION = 'C Corporation',
        S_CORPORATION = 'S Corporation',
        PARTNERSHIP = 'Partnership',
    }


}
