<template>
  <ValidationProvider :name="label" :rules="rules" :bails="true" v-slot="{ errors, valid }" :debounce="300">
    <v-text-field
      type="text"
      v-model="innerValue"
      :error-messages="error || errors"
      :success="rules && innerValue && String(innerValue).length && valid"
      :id="label"
      :label="$t(`fields.${label}`)"
      :hint="$t('hints.fields.telephone')"
      :placeholder="placeholder ? $t(`placeholders.${placeholder}`) : undefined"
      v-bind="$attrs"
      v-on="$listeners"
      persistent-hint
      @input="validate($event)"
    ></v-text-field>
  </ValidationProvider>
</template>

<script lang="ts">
import Vue from "vue";
import debounce from "lodash.debounce";
import { ValidationProvider } from "vee-validate";
import { i18n } from "@/plugins/i18n";
import { ValidatorsService } from "@/service";

export default Vue.extend({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  data: () => ({
    innerValue: "" as string | number,
    validate: (null as unknown) as (val?: string) => void,
    error: null as string | null
  }),
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    rules: {
      type: [String, Object],
      default: ""
    },
    // must be included in props
    value: {
      type: [String, Number],
      required: false
    }
  },
  methods: {
    async validateNumber(val?: string) {
      if (val && val.length) {
        const r = await ValidatorsService.validateTelephone({ number: val });
        if (r.valid) { this.error = null; this.innerValue = r.formatted || val; }
        else { this.error = this.errorMessage(this.label) }
      }
    },
    errorMessage(field: string): string {
      const tf = i18n.t(`fields.${field}`);
      return i18n.t("customValidations.messages.telephone", {_field_: tf}) as string;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    this.error = null;
    if (this.value) {
      this.innerValue = this.value;
    }
    this.validate = debounce(this.validateNumber, 300, { leading: false });
  }
});
</script>
