<template>
    <v-dialog v-if="!overlayLoading" persistent v-model="visible" max-width="700px">
        <v-card>
            <v-progress-linear v-if="cardLoading" indeterminate color="primary"></v-progress-linear>

            <v-card-title class="pb-0 mb-3">
                <v-toolbar flat dense>
                    <v-toolbar-title class="primary--text">
                        <slot name="tax-form-title">{{ modalTitle }}</slot>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn v-if="isTaxWindowOpen && !isMobile" class="me-4" color="primary" outlined @click="changeForm()">{{ $t('taxForm.changeForm') }}</v-btn>
                    <v-btn icon @click="dismiss()">
                        <v-icon color="primary">$close</v-icon>
                    </v-btn>
                </v-toolbar>
            </v-card-title>
            <v-card-subtitle class="ml-3" v-if="isW9TaxView">
                <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" rel="noopener noreferrer">{{ $t('taxForm.w-9.formInstructions') }}</a>
          </v-card-subtitle>
          <v-card-subtitle class="ml-3" v-if="isW8TaxView">
                <a href="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf" target="_blank" rel="noopener noreferrer">{{ $t('taxForm.w-8.formInstructions') }}</a>
          </v-card-subtitle>
            <v-card-text>
                <div v-if="!isTaxWindowOpen">
                    <p class="black--text font-weight-bold mb-2">{{ $t('taxForm.subtitle-1') }}</p>
                    <p class="black--text font-weight-bold mb-2">{{ $t('taxForm.subtitle-2') }} <a
                            href="https://www.irs.gov/forms-instructions" target="_blank" rel="noopener noreferrer">
                            {{ $t('captions.clickHere') }}</a>.
                    </p>
                    <p class="mb-7">{{ $t('taxForm.subtitle-3') }}</p>
                    <v-row>
                        <v-col cols="12" md="6">
                            <OSheet class="text-center h-100">
                                <v-btn class="mb-4" color="primary" @click="isW9TaxView = true; isW8TaxView= false">{{ $t('taxForm.w-9.title') }}</v-btn>
                                <p class="mb-0">{{ $t('taxForm.w-9.caption') }}</p>
                            </OSheet>
                        </v-col>
                        <v-col cols="12" md="6">
                            <OSheet class="text-center h-100">
                                <v-btn class="mb-4" color="primary" @click="isW8TaxView= true; isW9TaxView = false">{{ $t('taxForm.w-8.title') }}</v-btn>
                                <p class="mb-0">{{ $t('taxForm.w-8.caption') }}</p>
                            </OSheet>
                        </v-col>
                    </v-row>
                </div>
                <div v-else-if="isW9TaxView">
                    <v-btn v-if="isMobile" class="mt-3" color="primary" outlined @click="changeForm()">{{ $t('taxForm.changeForm') }}</v-btn>
                    <TaxformW9 :parentLoading="cardLoading" :prefilledRes="taxFormRes" :showW9Form="isW9TaxView" @submit-modal="submitModal()"></TaxformW9>
                </div>
                <div v-else-if="isW8TaxView">
                    <v-btn v-if="isMobile" class="mt-3" color="primary" outlined @click="changeForm()">{{ $t('taxForm.changeForm') }}</v-btn>
                    <TaxFormW8  :parentLoading="cardLoading"  :prefilledRes="taxFormRes" :showW8Form="isW8TaxView" @submit-modal="submitModal()"></TaxFormW8>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
    <div v-else>
        <v-overlay  absolute :value="overlayLoading">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
    </div>
</template>
<script lang="ts">
import Vue from "vue";
import OSheet from "@/framework/OSheet.vue";
import TaxformW9 from "../form/taxForm/TaxformW9.vue";
import { ConfirmDialogOptions } from "@/plugins/confirm";
import TaxFormW8 from "../form/taxForm/TaxFormW8.vue";
import { UserService, UserTaxInformationRecordItem } from "@/service";

import { RootStoreInterface } from "@/store/types";
import { createHelpers } from "vuex-typescript-interface";


const { mapGetters } = createHelpers<RootStoreInterface>();
const getters = mapGetters(["user"]);

export default Vue.extend({
    components: {
        OSheet,
        TaxformW9,
        TaxFormW8
    },
    data: () => ({
      cardLoading: false,
      isW9TaxView: false,
      isW8TaxView: false,
      visible: false,
      taxFormRes: {} as UserTaxInformationRecordItem,
      overlayLoading: false
    }),
    props: {
        isSessionTimeout: {
            type: Boolean,
            default: false
        }
    },
    computed:{
        ...getters,
      isTaxWindowOpen(): boolean {
        return this.isW8TaxView || this.isW9TaxView;
      },
      modalTitle(): string {
        if(!this.isTaxWindowOpen) {
            return this.$t('taxForm.heading') as string;
        } else if(this.isW9TaxView) {
            return this.$t('taxForm.w-9.formTitle') as string;
        } else {
            return this.$t('taxForm.w-8.formTitle') as string;
        }
      },
        isMobile(): boolean {
            return this.$vuetify.breakpoint.smAndDown;
        },
        userId(): number {
            return this.user?.id || 0;
        }
    },
    methods: {
        dismiss() {
            this.showDirtyAlert();
        },
        submitModal() {
            this.visible = false;
        },
        changeForm() {
            this.isW9TaxView = false;
            this.isW8TaxView = false;
            this.fetchFormData(true);
        },
        showTaxLayout() {
        this.visible = true
        this.$emit('tax-layout-open');
        this.isW8TaxView = false;
        this.isW9TaxView  = false;
    },
        async showDirtyAlert() {
        const options: ConfirmDialogOptions = {
            title: this.$t("headers.alert") as string,
            buttonTrueText:  this.$t("captions.leave") as string,
            buttonFalseText: this.$t("captions.stay") as string
      };
      if (await this.$confirm(`You have not completed this form. Your progress will be saved, but you won't be paid until the form is completed.`, options)) {
        this.visible = false;
      }
    },
    async fetchFormData(stopRedirection: boolean) {
            (!stopRedirection) ? this.overlayLoading = true : this.cardLoading = true;
            const id = this.userId;

            // reset response
            this.taxFormRes =  {};

            try {
                const res = await UserService.getUserTaxInformationByUId({ id: id as number });
                this.taxFormRes = res;

                if(stopRedirection) {
                    this.cardLoading = false;
                    this.overlayLoading = false;
                    return;
                } 

                if(this.taxFormRes.formType == UserTaxInformationRecordItem.formType.W_8_BEN_FORM) {
                    this.isW8TaxView = true;
                    this.isW9TaxView = false;
                } else if (this.taxFormRes.formType == UserTaxInformationRecordItem.formType.W_9_FORM) {
                    this.isW9TaxView = true;
                    this.isW8TaxView = false;
                } else {
                    this.isW8TaxView = false;
                    this.isW9TaxView = false;
                }

            } catch (e) {
                console.log('error', e);
            }       
            this.cardLoading = false;
            this.overlayLoading = false;
        },
   
    },
    watch: {
        visible(newVal) {
            if(newVal) {
                this.fetchFormData(false)
            }
        },
        isSessionTimeout(newVal) {
            // hide taxform layout component, when sesstion timeout dialog  appears
            if (newVal) {
                this.visible = false
                this.$emit('tax-layout-open');
            }
        }
    },
    created() {
        this.$eventBus.$on("showTaxForms", this.showTaxLayout);
    },
    destroyed() {
        this.$eventBus.$off("showTaxForms", this.showTaxLayout);
    }
})
</script>

<style lang="scss" scoped>
:deep(.v-toolbar__content) {
    padding-right: 0 !important;
}

:deep(.v-card__title) {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
}

:deep(.v-card__subtitle) {
    position: sticky;
    top: 60px;
    background: white;
    z-index: 9;
    padding-left: 12px !important;
    padding-bottom: 12px !important;
    border-bottom: 1px solid rgb(180, 178, 178);
}

 :deep(.v-toolbar__content) {
    padding-left: 0;
 }

 :deep(.v-btn) {
    width: 190px;
 }

 .h-100 {
    height: 100%;
 }
</style>