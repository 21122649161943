<template>
    <div class="parent-wrapper">
        <p v-if="!showReviewSection" class="black--text mt-3 font-weight-bold">{{ $t('taxForm.w-9.formCaption') }}</p>
        <validation-observer slim ref="observer" v-slot="{ invalid, handleSubmit }">
            <v-form v-if="!showReviewSection" autocomplete="off">
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h3 class="primary--text">{{ $t('taxForm.captions.nameCountry') }}</h3>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation v-model="w8Form.legalName" id="fullname" name="legalName"
                            placeholder="enterFullName" label="enterFullName" rules="required" :maxLength="255">
                        </VTextFieldWithValidation>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VCountrySelectorWithValidation class="list-items-spacing" v-model="w8Form.countryId" attach rules="required"
                             placeholder="countryCitizenship" label="countryCitizenship">
                        </VCountrySelectorWithValidation>
                    </v-col>
                </v-row>
                <v-divider class="mb-6"></v-divider>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h3 class="primary--text">{{ $t('taxForm.captions.permanentAddress') }}</h3>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation v-model="w8Form.address" name="address" clearable placeholder="addressWithApt"
                            label="addressWithApt" rules="required" :maxLength="255"></VTextFieldWithValidation>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation placeholder="city" name="city" clearable v-model="w8Form.city" label="city" rules="required" :maxLength="100"
                            ></VTextFieldWithValidation>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-country-states-selector-with-validation clearable v-model="w8Form.stateId" 
                            @state-selected="currentState" rules="" id="state" name="state" attach
                            placeholder="selectYourState" :countryId="w8Form.countryId ? w8Form.countryId : 0"
                            autocomplete="new-state" label="selectYourState" :disabled="!w8Form.countryId" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation clearable v-model="w8Form.zipCode"
                            placeholder="enterZipCode" name="zipcode" autocomplete="new-zip-code" label="enterZipCode" rules="" :maxLength="10">
                        </VTextFieldWithValidation>
                    </v-col>
                </v-row>
                <v-divider class="my-6"></v-divider>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h3 class="primary--text">{{ $t('taxForm.captions.identificationNumber') }}</h3>
                    </v-col>
                    <v-col cols="12" md="5">
                        <VTextFieldWithValidation v-model="w8Form.ssn" name="securityNumber"
                            rules="" label="securityNumber"  placeholder="securityNumber"
                            autocomplete="new-tin" :append-icon="showSecurityNumber ? '$eye' : '$eyeSlash'"
                            :type="showSecurityNumber ? 'text' : 'password'"
                            @click:append="showSecurityNumber = !showSecurityNumber" clearable
                             :maxLength="11"
                             />
                    </v-col>
                    <v-col cols="12" md="2" class="d-flex align-center justify-center"><span
                            class="primary--text text-center">OR</span></v-col>
                    <v-col cols="12" md="5">
                        <VTextFieldWithValidation v-model="w8Form.itin" name="individualIdentification"
                            rules="" label="itin" placeholder="itin" clearable
                            autocomplete="new-tin" :append-icon="showIdentificationNumber ? '$eye' : '$eyeSlash'"
                            :type="showIdentificationNumber ? 'text' : 'password'"
                            @click:append="showIdentificationNumber = !showIdentificationNumber" :readonly="true"
                            @focus="removeReadOnly" :maxLength="11"/>

                    </v-col>
                </v-row>
                <v-divider class="my-6"></v-divider>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h3 class="primary--text">{{ $t('taxForm.captions.foreignTaxNumber') }}</h3>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation v-model="w8Form.ftin" name="ftinNumber"
                            :rules="ftinRules" label="ftin" placeholder="ftin"
                            autocomplete="new-tin" :append-icon="showFtNum ? '$eye' : '$eyeSlash'"
                            :type="showFtNum ? 'text' : 'password'" @click:append="showFtNum = !showFtNum" clearable
                            :disabled="w8Form.ftinNotRequired" :maxLength="25"
                             />
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center" :class="{ 'justify-center': !isMobile }">
                        <v-checkbox v-model="w8Form.ftinNotRequired" color="primary"
                            class="black--text black--text custom-label"
                            :label="$t('fields.ftinNotRequired')"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VDateSelectorWithValidation clearable v-model="dateTimeStamp" name="dateSelector" rules="date" label="dob"
                            minMax="min" placeholder="dob" />
                    </v-col>
                </v-row>
                <v-divider class="my-6"></v-divider>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h3 class="primary--text">{{ $t('taxForm.captions.paypalInfo') }}</h3>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation v-model="w8Form.paypalEmail" name="paypalEmail" :rules="paypalMailRules"
                            label="Paypal Email" placeholder="Paypal Email" :readonly="true" @focus="removeReadOnly" :disabled="w8Form.doNotHavePaypal" :hint="'All payments will be issued via PayPal'" persistent-hint :multiLang="false" :maxLength="255" />
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex" :class="{ 'justify-center': !isMobile }">
                        <v-checkbox v-model="w8Form.doNotHavePaypal" color="primary"
                            class="black--text black--text custom-label" :label="$t('fields.paypalCheck')"></v-checkbox>
                    </v-col>

                    <v-col cols="12">
                        <v-row class="test" v-if="w8Form.doNotHavePaypal">
                            <v-col cols="12">
                                <p class="black--text font-weight-bold">Please give us the following information:</p>
                            </v-col>
                            <v-col cols="12" md="6">
                                <VTelephoneNumberWithValidation v-model="w8Form.personalPhoneNumber" name="phoneNumber" rules="required"
                                    label="personalNumber" placeholder="personalNumber" :maxLength="50"/>
                            </v-col>
                            <v-col cols="12" md="6">
                                <VTextFieldWithValidation v-model="w8Form.bankName" name="bankName" rules="required" label="bankName"
                                    placeholder="bankName" :maxLength="100" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <VTextFieldWithValidation v-model="w8Form.bankIban" name="bankCode" rules="required"
                                    label="bankswiftCode" placeholder="bankswiftCode" :maxLength="100" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mb-6"></v-divider>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h3 class="primary--text">{{ $t('taxForm.captions.certifyCountry') }}</h3>
                    </v-col>
                    <v-col cols="12" md="6">
                        <VCountrySelectorWithValidation v-model="w8Form.certificationCountry" rules="required"
                        attach>
                        </VCountrySelectorWithValidation>
                    </v-col>
                </v-row>
                <h3 class="primary--text">{{ $t('taxForm.captions.reviewCertification') }}</h3>
                <p class="black--text font-weight-bold">{{ $t('taxForm.captions.reviewCertificationContent') }}</p>
                <div class="text-center">
                    <v-btn color="primary" type="button" :disabled="invalid" @click="showReviewSection = true">{{
                        $t('taxForm.captions.review') }}</v-btn>
                </div>
            </v-form>
            <div v-else>
                <p class="black--text font-weight-bold">Review your W-8 BEN form data and certify to submit to our database
                </p>
                <v-row>
                    <v-col cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">{{ $t('placeholders.enterFullName') }} <v-icon
                                color="primary" class="cursor-pointer ms-1" small
                                @click="showReviewSection = false">$edit</v-icon></p>
                        <p class="black--text mb-0">{{ w8Form.legalName }}</p>
                    </v-col>
                    <v-col cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">Country of Citizenship <v-icon color="primary"
                                class="cursor-pointer ms-1" small @click="showReviewSection = false">$edit</v-icon></p>
                        <p class="black--text mb-0">{{ citizenshipCountryName  }}</p>
                    </v-col>
                    <v-col cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">{{ $t('account.titles.address') }} <v-icon
                                color="primary" class="cursor-pointer ms-1" small
                                @click="showReviewSection = false">$edit</v-icon></p>
                        <p class="black--text mb-0">{{ w8Form.address }}</p>
                        <p class="black--text mb-0">{{ w8Form.city }} {{ w8Form.state }} {{ w8Form.zipCode }} </p>
                    </v-col>
                    <v-col v-if="w8Form.ssn || w8Form.itin" cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">Taypayer Identification Number <v-icon
                                @click="showReviewSection = false; w8Form.ssn = ''; w8Form.itin=''" color="primary" class="cursor-pointer ms-1"
                                small>$edit</v-icon></p>
                        <p class="black--text mb-0">{{ !showTin ? showMaskValue : showTaxIdentificationNumber }} <v-icon v-if="showTaxIdentificationNumber" class="ms-2" small @click="showTin = !showTin">{{ !showTin ? '$eye' : '$eyeSlash'
                                }}</v-icon></p>
                    </v-col>
                    <v-col cols="12" md="6" v-if="w8Form.ftin">
                        <p class="font-weight-bold black--text mb-0">FTIN Number <v-icon
                                @click="showReviewSection = false; w8Form.ftin=''" color="primary" class="cursor-pointer ms-1"
                                small>$edit</v-icon></p>
                        <p class="black--text mb-0">{{ !ftinCheck ? showFtinMaskValue : w8Form.ftin }} <v-icon
                           class="ms-2" small @click="ftinCheck = !ftinCheck">{{ !ftinCheck ? '$eye' : '$eyeSlash'
                                }}</v-icon></p>
                    </v-col>
                    <v-col cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">Date of Birth <v-icon
                                @click="showReviewSection = false" color="primary" class="cursor-pointer ms-1"
                                small>$edit</v-icon></p>
                        <p class="black--text mb-0">{{ w8Form.dob }}</p>
                    </v-col>
                    <v-col v-if="!w8Form.doNotHavePaypal" cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">PayPal Email <v-icon
                                @click="showReviewSection = false" color="primary" class="cursor-pointer ms-1"
                                small>$edit</v-icon></p>
                        <p class="black--text mb-0">{{ !w8Form.doNotHavePaypal ? w8Form.paypalEmail : `I don't have Paypal`
                        }}</p>
                    </v-col>
                    <v-col v-if="w8Form.doNotHavePaypal" cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">Personal Phone Number <v-icon
                                @click="showReviewSection = false" color="primary" class="cursor-pointer ms-1"
                                small>$edit</v-icon></p>
                        <p class="black--text mb-0">{{ w8Form.personalPhoneNumber }}</p>
                    </v-col>
                    <v-col v-if="w8Form.doNotHavePaypal" cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">Bank Name <v-icon
                                @click="showReviewSection = false" color="primary" class="cursor-pointer ms-1"
                                small>$edit</v-icon></p>
                        <p class="black--text mb-0">{{ w8Form.bankName }}</p>
                    </v-col>
                    <v-col v-if="w8Form.doNotHavePaypal" cols="12" md="6">
                        <p class="font-weight-bold black--text mb-0">Bank IBAN BIC,or SWIFT code <v-icon
                                @click="showReviewSection = false" color="primary" class="cursor-pointer ms-1"
                                small>$edit</v-icon></p>
                        <p class="black--text mb-0">{{ w8Form.bankIban }}</p>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <p class="black--text font-weight-bold my-4">{{ $t('taxForm.typingName') }}</p>
                <v-list class="ps-0">
                    <v-list-group v-model="openCertificateSection">
                        <template v-slot:activator>
                            <v-list-item-title class=" primary--text font-weight-bold">{{ openCertificateSection ?
                                'Hide Certification Text' : 'View Certification Text'
                                }}</v-list-item-title>
                        </template>
                        <v-list-item class="px-0">
                            <div v-html="$t('taxForm.w-8.certificateContent')">

                            </div>
                        </v-list-item>
                    </v-list-group>
                </v-list>
                <v-row>
                    <v-col cols="12" md="6">
                        <VTextFieldWithValidation v-model="certificateName" placeholder="enterFullName"
                            label="enterFullName"
                            :rules="invalidNameMatch ? 'required|name|confirmed:fullname' : 'required|name'"
                            :maxLength="255">
                        </VTextFieldWithValidation>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-end align-center"
                        :class="{ 'justify-center': isMobile }">
                        <v-btn color="primary" type="submit" :loading="submitLoading" :disabled="!certificateName || invalidNameMatch"
                            @click="submitCertificate()">Certify</v-btn>
                    </v-col>
                </v-row>
            </div>
            <v-overlay absolute :value="overlayLoading">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </validation-observer>
    </div>
</template>
<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue';
import VTextFieldWithValidation from '../VTextFieldWithValidation.vue';
import { ValidationObserver } from "vee-validate";
import ErrorLabel from "@/components/objects/ErrorLabel.vue";
import { UserTaxInformationRecordItem, UserService, CountryRecordItem, UserPartialItem } from "@/service";
import VTaxClassificationSelector from '../VTaxClassificationSelector.vue';
import VCountryStatesSelectorWithValidation from '../VCountryStatesSelectorWithValidation.vue';
import VCountrySelectorWithValidation from '../VCountrySelectorWithValidation.vue';
import BirthDatePicker from "@/components/form/BirthDatePicker.vue";
import VTelephoneNumberWithValidation from "@/components/form/VTelephoneNumberWithValidation.vue";
import VDateSelectorWithValidation from "@/components/form/VDateSelectorWithValidation.vue";
import { DateTime } from "luxon";
import debounce from "lodash.debounce";

import { RootStoreInterface } from "@/store/types";
import { createHelpers } from "vuex-typescript-interface";
import ResponseHeader from '@/components/Discussion/ResponseHeader.vue';

const { mapGetters, mapActions } = createHelpers<RootStoreInterface>();
const getters = mapGetters(["user","countries"]);
const actions = mapActions(["updateTaxFormStatus", "loadCountries"]);

export default (Vue as VueConstructor<
    Vue & {
        $refs: {
            observer: InstanceType<typeof ValidationObserver>;
            errorLabel: InstanceType<typeof ErrorLabel>;
        };
    }
>).extend({
    components: {
        ValidationObserver,
        VTextFieldWithValidation,
        VTaxClassificationSelector,
        VCountryStatesSelectorWithValidation,
        VCountrySelectorWithValidation,
        BirthDatePicker,
        VTelephoneNumberWithValidation,
        VDateSelectorWithValidation
    },
    data: () => ({
        isInitializing: false,
        reload: (null as unknown) as () => void,
        country: {} as CountryRecordItem,
        overlayLoading: false,
        submitLoading: false,
        showTin: false,
        ftinCheck: false,
        entityType: "" as UserTaxInformationRecordItem.entityType,
        taxClarification: "" as UserTaxInformationRecordItem.taxClarification,
        showOtherEntity: false,
        showTaxClassfication: false,
        showOwnerShipCheckbox: false,
        countryId: 231,
        certificateName: "",
        showSecurityNumber: false,
        showFtNum: false,
        showIdentificationNumber: false,
        loading: false,
        openCertificateSection: false,
        recordId: undefined as number | undefined,
        showReviewSection: false,
        fetchRunning: false,
        dateTimeStamp: 0,
        w8Form: {
            id: undefined as number | undefined,
            legalName: "",
            formType: UserTaxInformationRecordItem.formType.W_8_BEN_FORM,
            stateId: undefined as number | undefined,
            certificationCountry: undefined as number | undefined,
            countryId: undefined as number | undefined,
            city: "",
            state: "",
            entityTypeOther: "",
            address: "",
            ftinNotRequired: false,
            ftin: "",
            doNotHavePaypal: false,
            ssn: "",
            ein: "",
            itin: "",
            dob: "",
            bankIban: "",
            bankName: "",
            personalPhoneNumber: '',
            paypalEmail: '',
            zipCode: undefined as number | undefined,
        } as UserTaxInformationRecordItem
    }),
    props: {
        prefilledRes: {
            type: Object as PropType<UserTaxInformationRecordItem>,
            default: () => ({} as UserTaxInformationRecordItem),
        },
        showW9Form: {
            type: Boolean,
            default: false
        },
        parentLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...getters,
        userId(): number {
            return this.user?.id || 0;
        },
        showMaskValue(): string {
            if (this.w8Form.ssn) {
                return '*'.repeat(this.w8Form.ssn.toString().length);
            } else if (this.w8Form.itin) {
                return '*'.repeat(this.w8Form.itin.toString().length);
            } else {
                return '';
            }

        },
        ftinRules(): string {
            return this.w8Form.ftinNotRequired ? '' : 'required';
        },
        paypalMailRules(): string {
            return this.w8Form.doNotHavePaypal ? '' : 'required|email';
        },
        showFtinMaskValue(): string {
            if (this.w8Form.ftin) {
                return '*'.repeat(this.w8Form.ftin.toString().length)
            } else {
                return '';
            }
        },
        showTaxIdentificationNumber() {
            return this.w8Form?.ssn?.length ? this.w8Form.ssn : this.w8Form.itin;
        },
        hasTaxidentificationNumber() {
            return this.w8Form?.ssn?.length || this.w8Form?.itin?.length
        },
        invalidNameMatch() {
            return this.w8Form.legalName == this.certificateName ? false : true;
        },
        isMobile(): boolean {
            return this.$vuetify.breakpoint.smAndDown;
        },
        taxFormNotStarted(): boolean {
            return this.user?.taxFormStatus == UserPartialItem.taxFormStatus.NOT_STARTED || this.user?.taxFormStatus == UserPartialItem.taxFormStatus.IN_PROGRESS;
        },
        citizenshipCountryName(): string {
          const match = this.countries?.find(c => c.id === this.w8Form.countryId);
          return match ? match.countryName : '';
        },
        shallowRelativityEls(): boolean {
           return  (this.w8Form.ftin || this.w8Form.ftinNotRequired || this.w8Form.paypalEmail || this.w8Form.doNotHavePaypal) ? true  : false
        }
    },
    methods: {
        ...actions,
        parseToTimestamp(dateString: string): number | null {
            if(!dateString) return null;
            const date = DateTime.fromFormat(dateString, "yyyy-MM-dd", { zone: "local" });
            return date.isValid ? date.toSeconds() : 0;
        },
        formatDateTimeRequirement(timestamp: number) {
            let d = DateTime.fromSeconds(timestamp);
            return d.toFormat("yyyy-MM-dd");
        },
            // handle clearable values - to pass key having empty  value in payload and trim values

        trimAddressPayload() {
            this.w8Form.city = this.w8Form.city ? this.w8Form.city?.trim() : '';
            this.w8Form.address = this.w8Form.address ? this.w8Form.address?.trim() : '';
            this.w8Form.zipCode = this.w8Form.zipCode ? this.w8Form.zipCode?.trim() : '';
        },
        async loadcountryData() {
            if (!this.countries) {
                try {
                    await this.loadCountries({});
                } catch (e) {
                    console.log(e);
                }
            }
        },
        getFilteredPayload() {

          // Trimming is causing double submit.  remove
            // this.trimAddressPayload();

            const excludeKeys = ["address", "zipCode", "city", "state", "stateId"];
            return Object.fromEntries(
                Object.entries(this.w8Form).filter(([key, value]) => {
                  // remove certify date on edit
                  if (key == "certificationDate") return false;

                    // Keep excluded keys without filtering
                    if (excludeKeys.includes(key)) return true;
                    return value !== undefined;
                })
            );
        },
       async updateFormData(response: UserTaxInformationRecordItem) {
            this.isInitializing = true;
            // load countries before binding the values.
            await  this.loadcountryData();

            this.w8Form.id = response?.id ? response.id : undefined;
            this.w8Form.legalName = response?.legalName ? response.legalName : undefined;
            this.w8Form.stateId = response?.stateId ? response.stateId : undefined;
            this.w8Form.certificationCountry = response?.certificationCountry  ? response.certificationCountry : undefined;
            this.w8Form.city = response?.city ? response.city : '';
            this.w8Form.countryId = response?.countryId ? response.countryId : undefined;
            this.w8Form.address = response?.address ? response.address : '';
            this.w8Form.state = response?.state ? response.state : '';
            this.w8Form.zipCode = response?.zipCode ? response.zipCode : '';
            this.w8Form.ftinNotRequired = response?.ftinNotRequired ? true : false;
            this.w8Form.paypalEmail = response?.paypalEmail ? response.paypalEmail : '';
            this.w8Form.doNotHavePaypal = response?.doNotHavePaypal ? true : false;
            this.w8Form.bankIban = response?.bankIban ? response.bankIban : undefined;
            this.w8Form.bankName = response?.bankName ? response.bankName : undefined;
            this.w8Form.personalPhoneNumber = response?.personalPhoneNumber ? response.personalPhoneNumber : undefined;
            this.w8Form.ssn = response?.ssn ? response.ssn : undefined;
            this.w8Form.itin = response?.itin ? response.itin : undefined;
            this.w8Form.ftin = response?.ftin ? response.ftin : undefined;
            this.w8Form.dob = response?.dob ? response.dob : undefined;
            this.dateTimeStamp = +(this.parseToTimestamp(this.w8Form.dob));
            this.$nextTick(() => {
                this.isInitializing = false;
                if(this.w8Form.id && this.shallowRelativityEls) this.$refs.observer.validate();
            });

        },

        async createFromData() {
            this.loading = true;
            const payload = this.getFilteredPayload();
            const id = this.userId;

            try {
                const res = await UserService.createTaxInformation({ id: id as number, requestBody: payload });
                if (res) this.w8Form.id = res.id;
            } catch (e) {
                console.log('error', e);
            }
            this.loading = false;
        },
        updateTaxFrom(formData: UserTaxInformationRecordItem) {
            this.loading = true;
            const payload = this.getFilteredPayload();
            const id = this.userId as number;
            try {
                UserService.updateTaxInformation({ id: id, requestBody: payload });
            } catch (e) {
                console.log('error', e);
            }
            this.loading = false;
        },
        saveFormData() {
            if (this.fetchRunning) return;
            if (this.parentLoading) return;

            if (!this.w8Form.id) {
                this.createFromData();
            } else {
                this.updateTaxFrom(this.w8Form);
            }
        },
        async submitCertificate() {
            this.submitLoading = true;
            if (!this.w8Form.certificationDate) this.w8Form.certificationDate = this.formatDateTimeRequirement(Math.floor(Date.now() / 1000));
            const payload = this.w8Form;
            const id = this.userId as number;
            try {
                await UserService.updateTaxInformation({ id: id, requestBody: payload });
                const userRes = await UserService.getUserById({ id: id });

                // update tax form status in global storage: local storage and FE user partial item
                if (this.taxFormNotStarted) this.updateTaxFormStatus(userRes.taxFormStatus)

                this.$emit('submit-modal');
                this.$confirm(`Your W-8 BEN Form has been successfully submitted. Your information has been stored and your next payment will be issued per our standard payment processing timelines.`, { icon: "", title: `W-8 BEN Form Submitted`, buttonTrueText: '', buttonFalseText: '' });
            } catch (e) {
                console.log('error', e);
            }
            this.submitLoading = false;

        },
        currentState(val: any) {
            if(!val) this.w8Form.state = '';
            this.w8Form.state = val.text ?? '';
        },
        removeReadOnly(event: any) {
            event.target.removeAttribute('readonly');
        }
    },
    watch: {
        'w8Form.entityType'(newVal) {
            this.showOtherEntity = newVal === UserTaxInformationRecordItem.entityType.OTHER;
            this.showTaxClassfication = newVal === UserTaxInformationRecordItem.entityType.LLC;
            this.showOwnerShipCheckbox = [UserTaxInformationRecordItem.entityType.TRUST_ESTATE, UserTaxInformationRecordItem.entityType.PARTNERSHIP].includes(newVal);
        },

        'w8Form.taxClarification'(newVal) {
            this.showOwnerShipCheckbox = newVal === UserTaxInformationRecordItem.taxClarification.PARTNERSHIP;
        },
        'w8Form.doNotHavePaypal'(newVal: boolean) {
          if (newVal) {
            this.w8Form.paypalEmail = ''; // Clear the email when checkbox is checked
          }
        },
        'w8Form.ftinNotRequired'(newVal) {
          if (newVal) {
            this.w8Form.ftin = ''; // Clears the number when checkbox is checked
          }
        },
        dateTimeStamp(newVal) {
            if (newVal) this.w8Form.dob = this.formatDateTimeRequirement(newVal);
        },
        showW8Form: {
            handler(newVal) {
                if (newVal) this.updateFormData(this.prefilledRes);
            }
        },
        prefilledRes(newVal) {
            if (newVal) {
                this.updateFormData(newVal);
            }
        },
        w8Form: {
            handler(newVal) {
                if (newVal) {
                    if (this.reload && !this.isInitializing) this.reload();
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.updateFormData(this.prefilledRes);
        this.reload = debounce(this.saveFormData, 1000, { leading: false });
    }
})
</script>
<style lang="scss" scoped>
:deep(.v-list),
.v-list {
    background-color: white !important;
}

:deep(.v-list-item) {
    padding-top: 0 !important;
    padding-bot: 0 !important;
}


.parent-wrapper {
    position: relative;
}
</style>