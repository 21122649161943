<template>
  <ValidationProvider :name="label" :rules="rules" v-slot="{ errors, valid }">
    <v-autocomplete
      :error-messages="errors"
      :success="Boolean(rules) && valid"
      :label="$t(`fields.${label}`)"
      :placeholder="placeholder ? $t(`placeholders.${placeholder}`) : undefined"
      :hint="hint ? $t(`hints.${hint}`) : undefined"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-if="hasItemSlot" v-slot:item="{ item }">
        <slot name="item" :item="item"></slot>
      </template>
      <template v-slot:append-item>
        <slot name="append-item"></slot>
      </template>
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
    </v-autocomplete>
  </ValidationProvider>
</template>

<script lang="ts">
import Vue from "vue";
import { ValidationProvider } from "vee-validate";

export default Vue.extend({
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    hint: {
      type: String,
      required: false
    }
  },
  computed: {
    hasItemSlot(): boolean {
      return !!this.$slots.item
    }
  }
});
</script>
