<template>
    <v-select-with-validation v-bind="$attrs" v-on="$listeners" :items="entityOptions" :label="label" :value="value"
        @input="updateValue" />
</template>

<script lang="ts">
import Vue from "vue";
import VSelectWithValidation from "./VSelectWithValidation.vue";
import { UserTaxInformationRecordItem } from "@/service";
export default Vue.extend({
    components: {
        VSelectWithValidation
    },
    props: {
        label: {
            type: String,
            default: "Entity Type"
        },
        value: {
            type: String,
            default: null
        }
    },
    computed: {
        entityOptions(): Array<{ value: string; text: string }> {
            if (!UserTaxInformationRecordItem.taxClarification) {
                return [];
            }

            return Object.keys(UserTaxInformationRecordItem.taxClarification).map((key) => {
                const value = UserTaxInformationRecordItem.taxClarification[key as keyof typeof UserTaxInformationRecordItem.taxClarification];

                return {
                    value: String(value), // Ensure it's a string
                    text: String(value)
                };
            });
        }
    },
    methods: {
        updateValue(selectedValue: string) {
            this.$emit("input", selectedValue);
        }
    }
});
</script>