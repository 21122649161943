<template>
  <ValidationProvider :name="label" :rules="rules" v-slot="{ errors, valid }">
    <v-select
      v-model="innerValue"
      class="text-capitalize"
      :error-messages="errors"
      :success="Boolean(rules) && valid"
      :id="label"
      :label="$t(`fields.${label}`)"
      :placeholder="placeholder ? $t(`placeholders.${placeholder}`) : undefined"
      v-bind="$attrs"
      v-on="$listeners"
    ></v-select>
  </ValidationProvider>
</template>

<script lang="ts">
import Vue from "vue";
import { ValidationProvider } from "vee-validate";

export default Vue.extend({
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      type: [Object, String, Number]
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
});
</script>
<style lang="scss" scoped>
:deep(.v-list-item__title) {
  text-transform: capitalize !important;
}
</style>
